<template>
    <div style="width:100%;height:40px;position: relative;color:white;" class="ksflex">
        <div class="charslider_left ksflexcol">{{title}}</div>
        <div class="charslider_right ksflexcol">{{content}}</div>
    </div>
</template>
<style scoped>
.charslider_left{
    border-radius: 5%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #063C95;
    border:1px solid #3274b8;
    width: 120px;
    height: 100%;
    justify-content: center;
}
.charslider_right{
    border-radius: 5%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #3197FA4f;
    border:1px solid #3274b8;
    border-left: none;
    flex: 1;
    height: 100%;
    justify-content: center;
}
</style>
<script>
import box from "@/components/box1.vue";
export default {
    props:["title","content"],
    components:{box}
}
</script>