<template>
  <div v-loading="loading" class="ksflexcol" style="height:100%">
    <div style="width:100%" class="ksflex">
      <div style="flex:1;">
        {{$t('实时数据')}}
      </div>
      <div style="flex:1;text-align:center">
        {{$t('数据刷新时间')}}
      </div>
      <div style="flex:1;text-align:right">
        <b-select :options="devs" style="width:200px" value-field="Sid" text-field="DeviceName" v-model="currentSid"></b-select>
      </div>
    </div>
    <div style="width:100%;flex:1" v-if="this.devs.length!=0">
      <sensor :sid=currentSid showpic='true'></sensor>
    </div>
    <div v-else>
      {{'暂无数据'}}
    </div>
  </div>
</template>
<script>
import sensor from "../sensor/components/sensordetail";
export default {
  components:{sensor},
  data(){
    return{
      loading:false,
      devs:[],
      currentSid:null
    }
  },
  mounted:function(){
    this.select();
  },
  methods:{
    change(value){
      this.currentSid=value;
    },
    select(){
      this.loading=true; 
      let req = {
          Index: 0,
          Count: 100000,
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetDeviceSensors", req, true)
          .then((data) => {
            this.devs = data.Items;
            if(this.devs.length!=0){
              this.currentSid=this.devs[0].Sid;
            }
            this.loading=false;
          })
          .catch((err) => {
            this.loading=false;
            this.$throw(err);
          });
    }
  }
}
</script>