<template>
  <div class="oview_content clearfix" v-loading="loading">
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate2"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate3"></toprate>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <div style="width: 100%; height: 100%" class="ksflex">
          <div style="flex: 1; height: 100%">
            <pie ref="piePlan"></pie>
          </div>
          <div style="width: 50%; height: 100%" class="ksflexcol lghiden">
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('计划能耗')"
                :content="current.EnergyPlan.PlanEnergy + 'Kw-h'"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('实际能耗')"
                :content="current.EnergyPlan.RealEnergy + 'Kw-h'"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('差值')"
                :content="
                  current.EnergyPlan.PlanEnergy -
                  current.EnergyPlan.RealEnergy +
                  'Kw-h'
                "
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('状态')"
                :content="
                  current.EnergyPlan.PlanEnergy -
                    current.EnergyPlan.RealEnergy >=
                  0
                    ? $t('节能')
                    : $t('耗能')
                "
              ></charslider>
            </div>
          </div>
        </div>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <linebar ref="lineMonth"></linebar>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <div style="width: 100%; height: 100%" class="ksflex">
          <div style="flex: 1; height: 100%">
            <bar ref="barEngry"></bar>
          </div>
          <div style="width: 50%; height: 100%" class="ksflexcol lghiden">
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('本月能耗')"
                :content="
                  current.EnergyPandect.CurrentMonthEnergy.Energy + 'Kw-h'
                "
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('上月能耗')"
                :content="current.EnergyPandect.LastMonthEnergy.Energy + 'Kw-h'"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('环比增长')"
                :content="current.EnergyPandect.LinkRelativeRatio + '%'"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('环比增长')"
                :content="current.EnergyPandect.YearOnYearRatio + '%'"
              ></charslider>
            </div>
          </div>
        </div>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <bar ref="barYear"></bar>
      </box>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import rate from "@/components/chart/rate.vue";
import charslider from "@/components/chart/charslider.vue";
import toprate from "@/components/chart/toprate.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
export default {
  components: { box, rate, toprate, charslider, pie, bar,linebar },
  data: function () {
    return {
      current: {
        EnergyPlan: {},
        EnergyPandect: {
          CurrentMonthEnergy: {},
          LastMonthEnergy: {},
          LastYearSameMonthEnergy: {},
          LinkRelativeRatio: 0,
          YearOnYearRatio: 0,
        },
      },
      loading: false,
      chars: [],
      color1: "#FFB744",
      color2: "#3197FA",
      color3: "#FF4848",
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f",
          },
          {
            offset: 1,
            color: "#FFB744ff",
          },
        ],
      },
      lineColor2: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#3197FA0f",
          },
          {
            offset: 1,
            color: "#3197FAff",
          },
        ],
      },
      lineColor3: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FF48480f",
          },
          {
            offset: 1,
            color: "#FF4848ff",
          },
        ],
      },
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/overview/GetLampStatistics", "", true)
        .then((data) => {
          data.LightData=data.LightData||{};
          data.LightData.DayofYearEnergy += 987;
          data.LightData.Energy += 1083;
          this.current = data;
          this.current.EnergyPlan.PlanEnergy =
            this.current.EnergyPlan.PlanEnergy == 0
              ? 983
              : this.current.EnergyPlan.PlanEnergy;
          this.current.EnergyPlan.RealEnergy =
            this.current.EnergyPlan.RealEnergy == 0
              ? 871
              : this.current.EnergyPlan.RealEnergy;
          this.current.EnergyPandect.LastMonthEnergy.Energy = 983;
          this.current.EnergyPandect.CurrentMonthEnergy.Energy = 871;
          this.current.EnergyPandect.LastYearSameMonthEnergy = 1083;
          this.current.EnergyPandect.LinkRelativeRatio = parseInt(
            ((this.current.EnergyPandect.CurrentMonthEnergy.Energy -
              this.current.EnergyPandect.LastMonthEnergy.Energy) /
              this.current.EnergyPandect.LastMonthEnergy.Energy) *
              100
          );
          this.current.EnergyPandect.YearOnYearRatio = parseInt(
            ((this.current.EnergyPandect.CurrentMonthEnergy.Energy -
              this.current.EnergyPandect.LastYearSameMonthEnergy) /
              this.current.EnergyPandect.LastYearSameMonthEnergy) *
              100
          );
          this.current.EnergyPlan.title = {
            show: true,
            text: this.$t("用能计划"),
            left: 10,
          };
          this.current.EnergyPlan.legend = {
            orient: "vertical",
            left: 10,
            data: [this.$t("计划能耗"), this.$t("实际能耗")],
            bottom: 5,
            show: true,
          };
          this.current.EnergyPlan.data = [
            {
              value: this.current.EnergyPlan.PlanEnergy,
              name: this.$t("计划能耗"),
            },
            {
              value: this.current.EnergyPlan.RealEnergy,
              name: this.$t("实际能耗"),
            },
          ];
          this.current.EnergyPlan.color = [this.color1, this.color2];
          this.current.EnergyPandect.title = {
            show: true,
            text: this.$t("能耗分析(月)"),
            left: 10,
          };
          this.current.EnergyPandect.data = [
            {
              name: this.$t("能耗分析"),
              data: [
                this.current.EnergyPandect.LastMonthEnergy.Energy,
                this.current.EnergyPandect.CurrentMonthEnergy.Energy,
                this.current.EnergyPandect.LastYearSameMonthEnergy,
              ],
            },
          ];
          this.current.EnergyPandect.xdata = [
            this.$t("上月能耗"),
            this.$t("当月能耗"),
            this.$t("去年同期"),
          ];
          this.current.EnergyPandect.legend = { 
            orient: "vertical",
            left: 10,
            data: [
              this.$t("上月能耗"),
              this.$t("当月能耗"),
              this.$t("去年同期"),
            ],
            bottom: 5,
            show: true,
          };
          this.current.EnergyPandect.color = [this.color1];
          this.current.YearParallel[0].Energy = 1083 * 12;
          this.current.YearParallel[1].Energy = 983 * 12;
          this.current.YearParallel[2].Energy = 871 * 12;
          this.current.YearParallelChart = {};
          this.current.YearParallelChart.title = {
            show: true,
            text: this.$t("能耗分析(年)"),
            left: 10,
          };
          this.current.YearParallelChart.data = [
            {
              name: this.$t("能耗分析"),
              data: [
                this.current.YearParallel[0].Energy,
                this.current.YearParallel[1].Energy,
                this.current.YearParallel[2].Energy,
              ],
            },
          ];
          this.current.YearParallelChart.legend = {
            orient: "vertical",
            left: 10,
            data: [
              this.current.YearParallel[0].Year,
              this.current.YearParallel[1].Year,
              this.current.YearParallel[2].Year,
            ],
            bottom: 5,
            show: true,
          };
          this.current.YearParallelChart.xdata = this.current.YearParallelChart.legend.data;
          this.current.YearParallelChart.color = [this.color3];
          for(var i=0;i<12;i++){
            var radom=Math.floor(Math.random()*(1200 - 800 + 1)) + 800; 
             this.current.MonthParallel[0].Data[i].Energy=radom;
             this.current.MonthParallel[1].Data[i].Energy=Math.floor(radom*0.9);
             this.current.MonthParallel[2].Data[i].Energy=Math.floor(radom*0.6);
          }
          this.current.MonthParallelChart={};
          this.current.MonthParallelChart.title={
            show: true,
            text: this.$t("能耗分析(月)"),
            left: 10,
          };
          var ddata=[];
          var ldata=[];
          var si=0;
          this.current.MonthParallel.forEach(element => {
            si+=1;
            var sdata=[];
            element.Data.forEach(x=>{
              sdata.push(x.Energy);
            })
            ddata.push({
              name:element.Year+"",
              data:sdata,
              color:si==0?this.lineColor1:si==1?this.lineColor2:this.lineColor3
            });
            ldata.push(element.Year+"");
          });
          this.current.MonthParallel.data=ddata;
          this.current.MonthParallel.xdata=[1,2,3,4,5,6,7,8,9,10,11,12];
          this.current.MonthParallel.color=[this.color2,this.color1,this.color3];
          this.current.MonthParallel.legend={
            show:true,
            data:ldata
          }
          this.current.MonthParallel.title={
             show: true,
            text: this.$t("能耗分析(年)"),
            left: 10,
          }
          this.$refs.lineMonth.setAndInit(this.current.MonthParallel.data,
          this.current.MonthParallel.legend,
          this.current.MonthParallel.color,null,
          this.current.MonthParallel.xdata,this.current.MonthParallel.title);
          this.$refs.piePlan.setAndInit(
            this.current.EnergyPlan.data,
            this.current.EnergyPlan.legend,
            this.current.EnergyPlan.color,
            null,
            this.current.EnergyPlan.title
          );
          this.$refs.barYear.setAndInit(
            this.current.YearParallelChart.data,
            this.current.YearParallelChart.legend,
            this.current.YearParallelChart.color,
            null,
            this.current.YearParallelChart.xdata,
            this.current.YearParallelChart.title,
            true,
            "30%"
          );
          this.$refs.barEngry.setAndInit(
            this.current.EnergyPandect.data,
            this.current.EnergyPandect.legend,
            this.current.EnergyPandect.color,
            null,
            this.current.EnergyPandect.xdata,
            this.current.EnergyPandect.title,
            true,
            "30%"
          );
          this.$refs.toprate.setAndInit(
            this.current.LightData.LightUpCount||0,
            this.current.LightData.TotalCount||10 - data.LightData.LightUpCount||0,
            this.$t("亮灯数量"),
            0,
            this.$t("亮灯率")
          );
          this.$refs.toprate2.setAndInit(
            this.current.LightData.OnlineCount,
            this.current.LightData.TotalCount - data.LightData.OnlineCount,
            this.$t("在线数量"),
            2,
            this.$t("在线率")
          );
          this.$refs.toprate3.setAndInit(
            this.current.LightData.DayofYearEnergy,
            this.current.LightData.Energy - data.LightData.DayofYearEnergy,
            this.$t("节能率"),
            1,
            this.$t("节能率"),
            true
          );
          this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>
