<template>
    <div class="devView" v-loading="loading" style="width:calc(100% - 10px);">

        <div class="ligthview_3" style="height:12%; display:none;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2"><h3>{{$t("人脸识别数量")}}</h3><small>Recognition Quantity</small></b-col>
                        <b-col>
                            <b-row style="height:100%; ">
                                <b-col style="border-right:1px solid #3274b8;">
                                    <p class="info_title">{{$t("今日人次")}}</p>
                                    <p class="info_val">{{current.FaceMessage.TodayCount}}</p>
                                </b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近7天人次")}}：</p><p class="info_val">{{current.FaceMessage.Nearly7DaysCount}}</p></b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近30天人次")}}：</p><p class="info_val">{{current.FaceMessage.Nearly30DaysCount}}</p></b-col>
                                <b-col><p class="info_title">{{$t("累计人次")}}：</p><p class="info_val">{{current.FaceMessage.TotalCount}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>

        <div class="ligthview_3" style="height:12%;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2"><h3>{{$t("人脸识别数量")}}</h3><small>Recognition Quantity</small></b-col>
                        <b-col>
                            <b-row style="height:100%; ">
                                <b-col style="border-right:1px solid #3274b8;">
                                    <p class="info_title">{{$t("今日人数")}}</p>
                                    <p class="info_val">{{current.FaceRecognizeMessage.TodayRecognizeCount}}</p>
                                </b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近7天人次")}}：</p><p class="info_val">{{current.FaceRecognizeMessage.Nearly7DaysRecognizeCount}}</p></b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近30天人次")}}：</p><p class="info_val">{{current.FaceRecognizeMessage.Nearly30DaysRecognizeCount}}</p></b-col>
                                <b-col><p class="info_title">{{$t("累计人次")}}：</p><p class="info_val">{{current.FaceRecognizeMessage.TotalRecognizeCount}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>

        <!-- 页头 -->
        <!--
    <div class="ligthview_l" style="text-align:center; border:none; padding-top:5px;">
        <datetime :amount=15 :length=5></datetime>
    </div>
    <div class="ligthview_l" style="text-align:center; border:none; padding-top:5px;">
        <number title="宾客来访：" :amount=15 :length=5></number>
    </div>
    <div class="ligthview_l" style="text-align:center; border:none; padding-top:5px;">
        <number title="员工统计：" :amount=122 :length=5></number>
    </div>
        -->
        <!-- 来访统计 -->
        <div class="ligthview_3">
            <div>
                <box>
                    <div style="height:100%; width:100%; padding-top:20px;" id="chatTop">
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_l" style="text-align:center; height:64%; width:32.33%;">
            <div>
                <box>
                    <div style="height:100%;width:100%;" id="chatRight"> </div>
                    <div class="container" style="top:auto;">
                        <div class="container_item">{{$t("男性")}} ：<span class="num_b">{{mydataChat2.num1}}</span>{{$t("位")}}  </div>
                        <div class="container_item"> <!--<el-button @click="onClickTest(1)" >del</el-button>--> {{$t("女性")}} <span class="num_b">{{mydataChat2.num2}}</span>{{$t("位")}}  </div>
                        <div class="container_item"> <!--<el-button @click="onClickTest()">add</el-button>--> {{$t("未知")}} <span class="num_b">{{mydataChat2.num3}}</span>{{$t("位")}}  </div>
                    </div>
                </box>
            </div>
        </div>

        <div ref="ligthObject" class="ligthview_l" style="text-align:center; height:64%; width:35.33%;">
            <div>
                <box>
                    <div style="width:100%; height:100%;" :style="camStyle">
                        <!-- <camininat v-if="camIsLocal" ref="camininat" :data='playdata' count="1" v-on:listenToCamininat="getCamStyle"></camininat> -->
                        <!-- <camininet v-if="!camIsLocal" ref="camininet" :data='playdata' v-on:listenToCamininat="getCamStyle"></camininet> -->
                            <netvideo ref="camininet"  v-if="!camIsLocal" :showRight="false" :playdata="playdata"></netvideo>
                        <!--<iframe id="frame-deview" allowtransparency="true" frameborder="0" scrolling="no"> </iframe>-->
                        <!--<el-switch v-model="camIsLocal" active-color="#13ce66" inactive-color="#ff4949" style="position:absolute; top:12px; right:12px; z-index:2;"> </el-switch>-->
                    </div>
                    <div class="chunk_content" style="max-height:222px; overflow:hidden; padding-top:10px; text-align:left; margin:0;transition:all 1s ease-out; white-space:nowrap;">
                        <transition-group name="face-img" tag="div">
                            <div class="chunk_item_91px" v-for="item in faceArray" :key="item.index">
                                <b-img v-if="!item.FaceUrl" class="face1_2" src="/img/unknow.png"></b-img>
                                <b-img v-if="item.FaceUrl" class="face1_2" :src="item.FaceUrl"></b-img>
                                <label class="face1_2_title">{{item.DetectTime.substr(-8, 5)}}</label>
                            </div>
                        </transition-group>
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_l" style="text-align:center; height:64%; width:32.33%;">
            <div>
                <box>
                    <div style="height:100%;width:100%; padding-top:20px;" id="chatLeft"> </div>
                </box>
            </div>
        </div>

        <div class="ligthview_l" style="text-align:center; height:50%; width:50%;">
            <div>
                <box>
                    <div style="height:100%;width:100%; padding-top:20px;" id="chatLeft2"> </div>
                    <div class="container" style="top:auto;">
                        <div class="container_item"> {{$t("普通客人")}}<span class="num_b">{{numArr[0]}}</span>{{$t("位")}} </div>
                        <div class="container_item"> {{$t("重要客人")}}<span class="num_b">{{numArr[1]}}</span>{{$t("位")}} </div>
                        <div class="container_item">{{$t("陌生人")}} <span class="num_b">{{numArr[2]}}</span>{{$t("位")}} </div>
                        <div class="container_item"> {{$t("嫌疑人")}}<span class="num_b">{{numArr[3]}}</span>{{$t("位")}} </div>
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_l" style="text-align:left; height:50%; width:50%;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2" style="min-width:125px;"><h3>{{$t("设备信息")}}</h3><small>Device Info</small></b-col>
                        <b-col>
                            <b-row style="padding-top:10%;">
                                <b-col class="info_col">{{$t("名称")}}：<span>{{current.DeviceInfo.DeviceName}}</span></b-col>
                                <b-col class="info_col">{{$t("序列号")}}：<span>{{current.DeviceInfo.Serial}}</span></b-col>
                            </b-row>
                            <b-row>
                                <b-col class="info_col">{{$t("是否绑定Ai任务")}}：<span>{{current.DeviceInfo.IsBinding==true?$t("是"):$t("否")}}</span></b-col>
                                <b-col class="info_col">{{$t("是否启动事件消息")}}：<span>{{current.DeviceInfo.AiKitStatus==1?$t("是"):$t("否")}}</span></b-col>
                            </b-row>
                            <b-row>
                                <b-col class="info_col">{{$t("类型")}}：<span>{{current.DeviceInfo.VendorType}}</span></b-col>
                                <b-col class="info_col">{{$t("状态")}}：<span>{{current.DeviceInfo.Status==0?$t("离线"):$t("在线")}}</span></b-col>
                            </b-row>
                            <b-row>
                                <b-col class="info_col" cols="6">{{$t("地址")}}：<span>{{current.DeviceInfo.Address}}</span></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>

        <div class="ligthview_3" style="height:20%; display:none;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2"><h3>{{$t("设备信息")}}</h3><small>Device Info</small></b-col>
                        <b-col>
                            <b-row style="height:50%;">
                                <b-col class="info_col">{{$t("名称")}}<span>{{current.DeviceInfo.DeviceName}}</span></b-col>
                                <b-col class="info_col">{{$t("序列号")}}<span>{{current.DeviceInfo.Serial}}</span></b-col>
                                <b-col class="info_col">{{$t("是否绑定Ai任务")}}<span>{{current.DeviceInfo.IsBinding==true?$t("是"):$t("否")}}}}</span></b-col>
                                <b-col class="info_col">{{$t("是否启动事件消息")}}<span>{{current.DeviceInfo.AiKitStatus==1?$t("是"):$t("否")}}}}</span></b-col>
                            </b-row>
                            <b-row style="height:50%;">
                                <b-col class="info_col" style="padding-top:0;">{{$t("类型")}}<span>{{current.DeviceInfo.VendorType}}</span></b-col>
                                <b-col class="info_col" style="padding-top:0;">{{$t("状态")}}><span>{{current.DeviceInfo.Status==0?$t("离线"):$t("在线")}}</span></b-col>
                                <b-col class="info_col" cols="6" style="padding-top:0;">{{$t("地址")}}：<span>{{current.DeviceInfo.Address}}</span></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>

        <div class="ligthview_3" style="display:none;">
            <div>
                <box>
                    <div style="height:100%; width:100%; padding-top:20px;" id="chatBottom">

                    </div>
                </box>
            </div>
        </div>

        <!--<div class="ligthview_2">
        <div>
            <box>

            </box>
        </div>
    </div>

    <div class="ligthview_2">
        <div>
            <box>

            </box>
        </div>
    </div>-->

        <div class="clear"></div>
    </div>
</template>

<style>
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .face-img-enter-active{
        transition: all .8s ease-in-out;
    }
    .face-img-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .face-img-enter, .face-img-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
        transform: translateX(100px);
        transform: translateY(30px);
        opacity: 0;
    }
    .face-img-move {
        transition: transform 1s;
    }

    #frame-deview {
        position: absolute;
        z-index: 1;
        width: 47px;
        height: 25px;
        top: 10px;
        right: 10px;
    }

    .ligthview_l .chunk_content .face1_2 {
        max-width: 89px;
        max-height: 93px;
        /*width:auto;*/
    }

    .ligthview_l .chunk_content .face1_2_title {
        font-size: 1rem;
        margin-top: 0;
    }

    .ligthview_l .chunk_content .chunk_item_91px {
        margin: 0 2px;
        /*width:auto;*/
    }

    .devView, .detailview {
        height: 100%;
    }

        .devView > div, .detailview > div {
            float: left;
        }

        .devView .detailview_2 {
            width: 100%;
            height: 35%;
        }

        .devView .ligthview_l {
            width: 33.33%;
            height: 100px
        }

            .devView .ligthview_l > div, .detailview .detailview_1 > div, .detailview .detailview_2 > div {
                padding: 5px;
                width: 100%;
                height: 100%;
            }

        .devView .ligthview_2 {
            width: 50%;
            height: 42%;
        }

            .devView .ligthview_2 > div {
                padding: 5px;
                width: 100%;
                height: 100%;
            }

        .devView .ligthview_l {
            width: 33.33%;
            height: 100px;
            float: left;
        }
            /* 人脸识别文字 */
            .devView .ligthview_l .container {
                position: absolute;
                bottom: 0;
                right: 0;
                text-align: left;
                width: 30%;
                z-index: 1;
                padding: 30px 20px;
                line-height: 1;
            }

        .devView .ligthview_2 .container .container_item {
            color: #eee;
            min-width: 173px;
        }

            .devView .ligthview_2 .container .container_item .num_b {
                color: #fff;
                font-size: 26px;
                padding: 0 5px;
            }


        /* 人脸识别文字 */
        .devView .ligthview_l .container {
            position: absolute;
            top: 0;
            right: 0;
            text-align: left;
            width: 30%;
            z-index: 1;
            padding: 30px 20px;
        }

            .devView .ligthview_l .container .container_item {
                color: #eee;
                min-width: 173px;
            }

                .devView .ligthview_l .container .container_item .num_b {
                    color: #fff;
                    font-size: 36px;
                    padding: 0 5px;
                }

        .devView .ligthview_3 > div {
            padding: 5px;
            width: 100%;
            height: 100%;
        }

        .devView .ligthview_3 {
            width: 100%;
            height: 42%;
        }

            .devView .ligthview_3 .info_list {
                color: #d1d1d1;
            }

                .devView .ligthview_3 .info_list h3 {
                    color: #fff;
                    font-size: 18px;
                    font-weight: bold;
                    padding: 20px 0 0 20px;
                    margin-bottom: 0;
                }

                .devView .ligthview_3 .info_list small {
                    padding: 0 0 0 20px;
                }

                .devView .ligthview_3 .info_list span {
                    color: #fff;
                }

                .devView .ligthview_3 .info_list .info_col {
                    padding: 20px;
                    font-size: 17px;
                }

                .devView .ligthview_3 .info_list .info_title {
                    text-align: center;
                    padding-top: 0%;
                    margin: 0;
                    display: inline-block;
                    font-size: 17px;
                }

                .devView .ligthview_3 .info_list .info_val {
                    display: inline-block;
                    text-align: center;
                    font-size: 44px;
                    color: #fff;
                    padding-left: 10px;
                }

        .devView .ligthview_l .info_list {
            color: #d1d1d1;
        }

            .devView .ligthview_l .info_list h3 {
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                padding: 20px 0 0 20px;
                margin-bottom: 0;
            }

            .devView .ligthview_l .info_list small {
                padding: 0 0 0 20px;
            }

            .devView .ligthview_l .info_list span {
                color: #fff;
            }

            .devView .ligthview_l .info_list .info_col {
                padding: 20px;
                font-size: 17px;
            }

            .devView .ligthview_l .info_list .info_title {
                text-align: center;
                padding-top: 0%;
                margin: 0;
                display: inline-block;
                font-size: 17px;
            }

            .devView .ligthview_l .info_list .info_val {
                display: inline-block;
                text-align: center;
                font-size: 44px;
                color: #fff;
                padding-left: 10px;
            }
</style>

<script>
    import box from "@/components/box.vue"
    import number from "@/components/number.vue"
    import datetime from "@/components/datetime.vue"
import netvideo from '@/components/netvideo'
    export default {
        components: { box, number, datetime, netvideo },
        data: function () {
            return {
                loading: false,
                sid: null,
                current: {
                    DeviceInfo: {},
                    FaceMessage: {},
                    FaceRecognition: {},
                    FaceRecognizeMessage: {},
                    Sex: {},
                    Age: {},
                },
                mydataChat1: {},
                mydataChat2: {},
                numArr: [],
                chatTop: null,
                chatLeft: null,
                chatRight: null,
                chatBottom: null,
                camDevice: null,

                color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#2978c9' },
                    { offset: 0.5, color: '#00b2e4' },
                    { offset: 1, color: '#00e7fd' }]),
                color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#ffad42' },
                    { offset: 0.5, color: '#ffc54b' },
                    { offset: 1, color: '#ffd450' }]),
                color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7500f' },
                    { offset: 0.5, color: '#eec54b' },
                    { offset: 1, color: '#f7500f' }]),
                color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7510f' },
                    { offset: 0.5, color: '#ee154b' },
                    { offset: 1, color: '#f75020' }]),
                color5: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#6BC416' },
                    { offset: 0.5, color: '#119857' },
                    { offset: 1, color: '#066336' }]),
                color6: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#4A89CC' },
                    { offset: 0.5, color: '#175698' },
                    { offset: 1, color: '#073463' }]),
                color7: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#E766E5' },
                    { offset: 0.5, color: '#EF34D7' },
                    { offset: 1, color: '#9B118A' }]),
                color8: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#F4F4F4' },
                    { offset: 0.5, color: '#E9E9E9' },
                    { offset: 1, color: '#BBBBBB' }]),
                color9: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#BFBFCA' },
                    { offset: 0.5, color: '#C0BBC6' },
                    { offset: 1, color: '#B5BBC0' }]),
                color10: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#323232' },
                    { offset: 0.5, color: '#282828' },
                    { offset: 1, color: '#211010' }]),
                colorArr: [this.color1, this.color2, this.color3, this.color4, this.color5, this.color6, this.color7, this.color8, this.color9, this.color10],
                cameraArray: [],
                playdata: {},
                camIsLocal: true,
                camStyle: null,
                faceArray: [
                ],
                faceCount: 0,
            }
        },
        computed: {
            faceArrayMaxCount: function () {
                let count = 0;
                let pw = this.$refs.ligthObject.clientWidth - 30;
                //count = Math.floor(pw / 95);
                count = Math.round(pw / 95);
                //count = Math.ceiling(pw / 95);
                return count;
            },
        },
        mounted: function () {
            this.initData();
            if (this.$route.query.isLocal)
                this.camIsLocal = this.$route.query.isLocal === "true";
            this.select();
            this.chatTop = window.echarts.init(document.getElementById("chatTop"));
            this.chatLeft = window.echarts.init(document.getElementById("chatLeft"));
            this.chatLeft2 = window.echarts.init(document.getElementById("chatLeft2"));
            this.chatRight = window.echarts.init(document.getElementById("chatRight"));
            this.rightBottom = window.echarts.init(document.getElementById("chatBottom"));
            this.chatBottom = window.echarts.init(document.getElementById("chatBottom"));
        },
        methods: {
            getFaceAutoCount: function () {
                return this.faceCount++;
            },
            initData: function () {
                this.faceArray = [];
                var data = { "DetectTime": "2019/8/15 --:--:33" };
                for (var i = 0; i < this.faceArrayMaxCount; i++) {
                    data.index = this.getFaceAutoCount();
                    this.faceArray.push(data);
                }
            },
            onTest: function () {
                //let pw = this.$refs.ligthObject.offsetWidth;
            },
            onClickTest: function (ret) {
                switch (ret) {
                    case 1:
                        this.faceArray.pop();
                        break;
                    default:
                        while (this.faceArray.length >= this.faceArrayMaxCount) {
                            this.faceArray.pop();
                        }
                        var data = { "DetectTime": "2019/8/15 --:--:33" };
                        data.index = this.getFaceAutoCount();
                        if (this.faceCount % 2) {
                            data.FaceUrl = 1;
                        } 
                        this.faceArray.splice(0, 0, data);
                }
            },
            getCamStyle: function (data) {
                this.camStyle = data;
            },
            // 获取设备列表
            getDevices() {
                new Promise((res, rej) => {
                    let obj = {
                        Index: 0,
                        Count: 1000,
                        IsBinding: true // 是否绑定AiKit任务
                    }
                    this.$ksajax.ksPostPromise("/api/device/GetAiKitVideos", obj, true).then((data) => {
                        data.Items.forEach(x => {
                            x.DeviceName += x.AiKitStatus == 1 ? this.$t("开启") :this.$t("关闭");
                        });
                        this.cameraArray = data.Items;
                        res();
                        throw this.$t("请求成功");
                    }).catch(err => { rej(err); })
                }).then(() => { }).catch((err) => { this.$throw(err); })
            },
            // choose 摄像机
            choseCamera(sid) {
                let device = {};
                for (var i = 0; i < this.cameraArray.length; i++) {
                    if (this.cameraArray[i].Sid === sid) {
                        device = this.cameraArray[i];
                    }
                }
                this.camDevice = device;
                var url;
                if (this.camIsLocal) {
                    // 局域网播放
                    url = "/api/device/GetPayNat";
                    this.$ksajax.ksPostData(url, sid, true, (success) => {

                        device.Ip = success.Ip;
                        device.Password = success.Password;
                        device.Username = success.Username;
                        device.DeviceName = success.DeviceName;
                        device.Port = success.Port;

                        this.$refs.camininat.start(device, () => {
                        }, (data) => {
                            this.$throw(data);
                        });
                    })

                } else {
                    // 广域网播放
                    //播放
                    var req = this.package(sid, 'hd');
                    url = "/api/device/GetPayNet";
                    this.$ksajax.ksPostData(url, req, true, (success) => {
                        this.playdata.data = success;
                        this.playdata.play = true;
                        this.playdata.device = device;
                        this.$refs.camininet.start();
                    })
                }

            },
            // 广域网请求头封装
            package(sid, hd) {
                return {
                    "EzopenType": 0,
                    "Channel": 1,
                    "Definition": hd,
                    "PlaybackSource": 0,
                    "Sid": sid
                }
            },

            getOption(xAxis, yAxis, datas) {
                var obj = {
                    textStyle: {
                        color: '#8495c2'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: xAxis,
                    yAxis: yAxis,
                    series: datas,
                    legend: {
                        data: [],
                        textStyle: {
                            color: '#ffffff'
                        },
                        orient: 'vertical',
                        right: 50,
                    }
                }
                return obj;
            },
            //  左扇形图
            beginLeftTop(num1, num2, num3, num4) {
                this.mydataChat1 = {
                    num1: num1,
                    num2: num2,
                    num3: num3,
                    num4: num4,
                }
                var sum = num1 + num2 + num3 + num4;
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('普通客人'), this.$t('重要客人'), this.$t('陌生人'), this.$t('嫌疑人')],
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['60%', '90%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sum}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: num1, name: this.$t('普通客人') },
                                { value: num2, name: this.$t('重要客人') },
                                { value: num3, name: this.$t('陌生人') },
                                { value: num4, name: this.$t('嫌疑人') },
                            ],
                            color: [this.color1, this.color2, this.color3, this.color4]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: this.$t("人物识别比例"),
                    subtext: "Person Ratio",
                    "padding": [0, 0, 0, 0],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.chatLeft.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.chatLeft.resize();
                });
            },
            // 右扇形图
            beginLeftBottom(j, s, t) {
                this.mydataChat2 = {
                    num1: j,
                    num2: s,
                    num3: t,
                }
                var on = j
                var off = s;
                var sub = j + s + t;
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('男性'), this.$t('女性'), this.$t('未知')],
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['50%', '70%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sub}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'normal'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [
                                { value: on, name: this.$t('男性') },
                                { value: off, name: this.$t('女性') },
                                { value: t, name: this.$t('未知') },
                            ],
                            color: [this.color1, this.color3, this.color4]
                        },
                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: this.$t("性别比例"),
                    subtext: "Sex Ratio",
                    "padding": [20, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.chatRight.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.chatRight.resize();
                });
            },
            // 年龄分布饼状图
            pieAgeChart(numArr, nameArr, object) {
                this.numArr = numArr;
                //var sum = numArr.reduce(function (x, y) { return x + y; });
                var sum = 0;
                var dataArr = [];
                var count = 0;
                for(let d in numArr) {
                    dataArr.push({ value: numArr[d], name: nameArr[count++] });
                    sum += numArr[d];
                }

                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: nameArr,
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            startAngle: 45,
                            radius: ['30%', '70%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sum}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'normal'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: dataArr,
                            color: [this.color1, this.color2, this.color3, this.color4, this.color5, this.color6, this.color7, this.color8, this.color9, this.color10],
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: this.$t("年齡分布"),
                    subtext: "Person Type",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                object.setOption(option, true);

                window.addEventListener("resize", () => {
                    object.resize();
                });
            },
            // 人物类型分布
            piePersonType(numArr, object) {
                this.numArr = numArr;
                var sum = numArr.reduce(function (x, y) {
                    return x + y;
                });
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('普通客人'), this.$t('重要客人'), this.$t('陌生人'), this.$t('嫌疑人')],
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['50%', '70%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sum}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'normal'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [
                                { value: numArr[0], name: this.$t('普通客人') },
                                { value: numArr[1], name: this.$t('重要客人') },
                                { value: numArr[2], name: this.$t('陌生人') },
                                { value: numArr[3], name: this.$t('嫌疑人') },
                            ],
                            color: [this.color1, this.color2, this.color3, this.color4]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: "人物类型分布",
                    subtext: "Person Type",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                object.setOption(option, true);

                window.addEventListener("resize", () => {
                    object.resize();
                });
            },
            // 左条形统计图
            beginLeftBarGraph(num1, num2, num3, num4) {
                var datas = [];
                datas.push({
                    name: "数量",
                    type: "bar",
                    stack: "总量",
                    smooth: true,
                    data: [num1, num2, num3, num4],
                    barCategoryGap: "50%",
                    itemStyle: {
                        color: '#ca8622'
                    }
                });
                var x = {
                    type: "category",
                    data: [this.$t('普通客人'), this.$t('重要客人'), this.$t('陌生人'), this.$t('嫌疑人')],
                    splitLine: { show: false },
                    z: 10
                };
                var y = {
                    type: "value",
                    splitLine: { show: false },
                    z: 10
                };
                var option = this.getOption(x, y, datas);
                (option.title = {
                    show: true,
                    text: "人物类型分布",
                    subtext: "Person Distrubution",
                    padding: [0, 0, 0, 20],
                    textStyle: {
                        color: "#ffffff"
                    }
                }),
                    this.chatLeft.setOption(option, true);
                window.addEventListener("resize", () => {
                    this.chatLeft.resize();
                });
            },
            // 底部条形统计图
            beginChatBottom(arrTrend) {
                //var data = this.current.Resources;
                var d = arrTrend;
                var datas = [];
                datas.push({
                    name: this.$t('数量'),
                    type: 'bar',
                    stack: this.$t('总数量'),
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: "white"
                        }
                    },
                    data: [d.LessThan10Count, d.Between10And20Count, d.Between20And30Count, d.Between30And40Count, d.Between40And50Count, d.Between50And60Count, d.Between60And70Count, d.Between70And80Count, d.GreaterThan80Count, d.UnknowCount],
                    itemStyle: {
                        color: "#1b85c9"
                    },
                });
                var x = {
                    type: "category",
                    boundaryGap: false,
                    data: [this.$t('10岁以下'), this.$t('10~20岁'), this.$t('20~30岁'), this.$t('30~40岁'), this.$t('40~50岁'), this.$t('50~60岁'), this.$t('60~70岁'), this.$t('70~80岁'), this.$t('80岁以上'), this.$t('未知')],
                }
                var y = {
                    splitLine: {
                        lineStyle: {
                            color: "#8495c2"
                        }
                    },
                    type: "value"
                }
                var option = this.getOption(x, y, datas);
                option.title = {
                    show: true,
                    text: "年龄分布",
                    subtext: "Age Distribution",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                },
                    this.chatBottom.setOption(option);

                window.addEventListener("resize", () => {
                    this.chatBottom.resize();
                });
            },
            // 每天识别数量折线图
            beginChatTop(arrTrend) {
                var online = [];
                var labels = [];
                for (var i = 0; i < arrTrend.length; i++) {
                    online.push(arrTrend[i].Count);
                    labels.push(arrTrend[i].Month + "/" + arrTrend[i].Day);
                }

                var datas = [];
                datas.push({
                    name: this.$t('数量'),
                    type: 'line',
                    stack: this.$t('总数量'),
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: "white",
                            fontSize: 20,
                        },
                    },
                    symbolSize:15,
                    smooth: true,
                    data: online,
                    itemStyle: {
                        //color: this.color2,
                        color: '#ffb669',
                        borderWidth:2,
                    },
                    areaStyle: {
                        normal: {
                            color: new window.echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                //[
                                //    { offset: 0, color: '#00749a' },
                                //    { offset: 0.5, color: '#123e62' },
                                //    { offset: 1, color: '#132f57' }
                                //],
                                [
                                    { offset: 0, color: '#ffb669' },
                                    { offset: 0.5, color: '#ea413f' },
                                    { offset: 1, color: '#132f57' }
                                ]
                            )
                        }
                    },
                    lineStyle: {
                            width: 5,
                },
                    markPoint: {
                        symbolSize:100
                    }
                });
                var x = {
                    type: "category",
                    boundaryGap: false,
                    data: labels
                }
                var y = {
                    splitLine: {
                        lineStyle: {
                            color: "#8495c2",

                        }
                    },
                    type: "value"
                }
                var option = this.getOption(x, y, datas);
                option.title = {
                    show: true,
                    text: this.$t("人脸信息趋势"),
                    subtext: "Face Recognition Trends",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                },
                    this.chatTop.setOption(option);

                window.addEventListener("resize", () => {
                    this.chatTop.resize();
                });
            },

            select() {
                this.sid = this.$route.query.sid;
                this.choseCamera(this.sid);
                this.loading = true;
                this.$ksajax.ksPostPromise("/api/face/GetStatisticsByDevSid", this.sid, true).then(data => {
                    this.loading = false;
                    this.current = data;
                    // 普通客人、重要客人、陌生人、嫌疑人
                    //this.beginLeftBarGraph(df.RegularCount, df.VipCount, df.StrangerCount, df.SuspectCount);
                    //var ageNameArr = [this.$t('10岁以下'), this.$t('10~20岁'), this.$t('20~30岁'), this.$t('30~40岁'), this.$t('40~50岁'), this.$t('50~60岁'), this.$t('60~70岁'), this.$t('70~80岁'), this.$t('80岁以上'), this.$t('未知')];
                    var ageNameArr = [this.$t('10后'), this.$t('00后'), this.$t('90后'), this.$t('80后'), this.$t('70后'), this.$t('60后'), this.$t('50后'), this.$t('40后'), this.$t('80岁以上'), this.$t('未知')];
                    this.pieAgeChart(data.Age, ageNameArr, this.chatLeft);
                    // 人物类型分布
                    var df = data.FaceRecognition;
                    this.piePersonType([df.RegularCount, df.VipCount, df.StrangerCount, df.SuspectCount], this.chatLeft2);
                    var ds = data.Sex;
                    // 男性、女性
                    this.beginLeftBottom(ds.MaleCount, ds.FemaleCount, ds.UnknownCount);
                    // 每天识别数量折线图
                    this.beginChatBottom(data.Age);
                    // 每天识别数量折线图
                    this.beginChatTop(data.RecognitionTrend);
                }).catch(err => {
                    this.$throw(err);
                    this.loading = false;
                });
            },

            // 获取人脸检测信息 Type=0  （实时抓拍）
            getFaceBySid() {
                new Promise((res, rej) => {
                    this.$ksajax
                        .ksPostPromise("/api/face/GetFaceBySid", this.faceSid, true)
                        .then(data => {
                            // 保持6个
                            while (this.faceArray.length >= this.faceArrayMaxCount) {
                                this.faceArray.pop();
                            }
                            data.index = this.getFaceAutoCount();
                            this.faceArray.splice(0, 0, data);
                            res();
                        })
                        .catch(err => {
                            rej(err);
                            this.$throw(err);
                        });
                });
            },
        },
        watch: {
            // singlar
            '$store.state.faceHubChange': function () {
                var data = this.$store.state.faceHub;
                var type = data.Type;
                this.faceSid = data.Sid;
                if (data.Sid == "abc") {
                    var r = Math.random(9);
                    if (r > 6) {
                        // 人脸对比
                        type = 0;
                        this.faceSid = "FACE12104820Hf4e7I4990Kbb90Hbb8506ee0b42";
                    } else if (r > 3) {
                        // 人脸检测
                        type = 0;
                        this.faceSid = "FACE60f0d3e7H29e6I46e6K8f62H5e89c4853be7";
                    } else {
                        // 人形检测
                        type = 0;
                        this.faceSid = "FACE60f0d3e7H29e6I46e6K8f62H5e89c4853be7";
                    }
                }
                switch (type) {
                    case 0: // 0表示人脸检测信息。
                        // 过滤非本摄像头
                        if(this.sid === data.DeviceSid)
                            this.getFaceBySid();
                        break;
                    case 1: // 1表示人脸告警信息。
                        //this.getFaceWarnBySid();
                        break;
                    default:
                        this.$throw("-类型");
                        break;
                }
            }
        },
    }
</script>

