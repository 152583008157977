<template>
  <div class="oview_content clearfix" v-loading="loading">
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate2"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate3"></toprate>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <div style="width: 100%; height: 100%" class="ksflex">
          <div style="flex: 1; height: 100%">
            <pie ref="piePlan" :radius="true" :centerlable="$t('总数量')"></pie>
          </div>
          <div style="width:45%; height: 100%" class="ksflexcol lghiden">
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('普通客人')"
                :content="current.FaceRecognition.RegularCount + $t('位')"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('重要客人')"
                :content="current.FaceRecognition.VipCount + $t('位')"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('陌生人')"
                :content="current.FaceRecognition.StrangerCount + $t('位')"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('嫌疑人')"
                :content="current.FaceRecognition.SuspectCount + $t('位')"
              ></charslider>
            </div>
          </div>
        </div>
      </box>
    </div>
    <div class="oview_content_2">
      <box>
        <div style="width: 100%; height: 100%" class="ksflex">
          <div style="flex: 1; height: 100%">
            <pie ref="piePlan1" :radius="true" :centerlable="$t('总数量')"></pie>
          </div>
          <div style="width:45%; height: 100%" class="ksflexcol lghiden">
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('男性')"
                :content="current.Sex.MaleCount + $t('位')"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('女性')"
                :content="current.Sex.FemaleCount + $t('位')"
              ></charslider>
            </div>
            <div
              style="
                flex: 1;
                width: 100%;
                position: relative;
                padding: 0px 15px 8px 0;
              "
            >
              <charslider
                :title="$t('未知')"
                :content="current.Sex.UnknownCount + $t('位')"
              ></charslider>
            </div>
          </div>
        </div>
      </box>
    </div>
    <div class="oview_content_2 oview_w100">
      <box>
        <linebar ref="line" :smooth="true"></linebar>
      </box>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import rate from "@/components/chart/rate.vue";
import charslider from "@/components/chart/charslider.vue";
import toprate from "@/components/chart/toprate.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
export default {
  components: { box, rate, toprate, charslider, pie, bar, linebar },
  data: function () {
    return {
      current: {
        FaceRecognition: {},
        RecognitionTrend: {},
        Sex: {},
      },
      loading: false,
      chars: [],
      color1: "#FFB744",
      color2: "#3197FA",
      color3: "#FF4848",
      color4:"#31B748",
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f",
          },
          {
            offset: 1,
            color: "#FFB744ff",
          },
        ],
      },
      lineColor2: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#3197FA0f",
          },
          {
            offset: 1,
            color: "#3197FAff",
          },
        ],
      },
      lineColor3: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FF48480f",
          },
          {
            offset: 1,
            color: "#FF4848ff",
          },
        ],
      },
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/overview/GetFaceStatistics", "", true)
        .then((data) => {
          this.loading = false;
          this.current = data;
          var chart1 = {
            title: {
              show: true,
              text: this.$t("人脸识别比例"),
              left: 10,
            },
            legend:{
              orient: "vertical",
              left: 10,
              data: [this.$t("普通客人"), this.$t("重要客人"),this.$t("陌生人"), this.$t("嫌疑人")],
              bottom: 5,
              show: true,
            },
            data:[
              {
                value:this.current.FaceRecognition.RegularCount,
                name:this.$t("普通客人")
              },
              {
                value:this.current.FaceRecognition.VipCount,
                name:this.$t("重要客人")
              },
              {
                value:this.current.FaceRecognition.StrangerCount,
                name:this.$t("陌生人")
              },
              {
                value:this.current.FaceRecognition.SuspectCount,
                name:this.$t("嫌疑人")
              }
            ],
            color: [this.color1, this.color2, this.color3,this.color4]
          };
          this.$refs.piePlan.setAndInit(chart1.data,chart1.legend,chart1.color,null,chart1.title)
          chart1.title.text="性别识别比例";
          chart1.legend.data=[this.$t("男性"), this.$t("女性"),this.$t("未知")];
          chart1.data=[
              {
                value:this.current.Sex.MaleCount,
                name:this.$t("男性")
              },
              {
                value:this.current.Sex.FemaleCount,
                name:this.$t("女性")
              },
              {
                value:this.current.Sex.UnknownCount,
                name:this.$t("未知")
              }
          ]; 
          this.$refs.piePlan1.setAndInit(chart1.data,chart1.legend,chart1.color,null,chart1.title);
          chart1.title.text="人脸识别趋势分析";
          chart1.legend=null;
          var ddata=[];
          chart1.xdata=[];
          this.current.RecognitionTrend.forEach(element => {
            ddata.push(element.Count);
            chart1.xdata.push(`${element.Month}.${element.Day}`);
          });
          chart1.data=[{
            name:this.$t("数量"),
            data:ddata,
            color:this.lineColor1
          }];
          this.$refs.line.setAndInit(chart1.data,null,chart1.color,null, chart1.xdata, chart1.title);
          this.$refs.toprate.setAndInit(
            data.DeviceCount,
            0,
            this.$t("设备数量"),
            0,
            this.$t("在线率"),
            false
          );
          this.$refs.toprate2.setAndInit(
            data.FaceSetCount,
            0,
            this.$t("人脸采集数量"),
            1,
            this.$t("采集率"),
            false
          );
          this.$refs.toprate3.setAndInit(
            data.MessageCount,
            0,
            this.$t("采集消息数量"),
            3,
            this.$t("采集率"),
            false
          );
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>
