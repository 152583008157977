<template>
  <div class="info_login">
    <div class="info_login_img">
      <el-carousel trigger="click" style="height:100%;" :interval="5000">
        <el-carousel-item v-for="item in pics" :key="item">
          <el-image class="d-block img-fluid w-100" style="height:100%;" :src="item"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="info_login_content">
      <div>
        <p
          id="info_login_content_title"
          style="font-size:36px;color:white;text-align:center"
        >{{$t('智慧城市平台')}}</p>
        <p 
          style="font-size:20px;color:white;text-align:center;display:none"
        >{{$t("聚合")}} · {{$t("互联")}} · {{$t("智慧")}}</p>
      </div>
      <div class="info_login_content_box">
        <p style="font-size:26px;color:#333333">{{$t("密码登录")}}</p>
        <b-form @submit="onSubmit" v-if="show">
          <b-form-group id="exampleInputGroup1" label-for="exampleInput1">
            <b-form-input
              id="exampleInput1"
              type="text"
              v-model="form.UserName"
              required
              v-bind:placeholder="$t('请输入用户名')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="exampleInputGroup2"
            label-for="exampleInput2"
            style="margin:30px 0 30px 0"
          >
            <b-form-input
              id="exampleInput2"
              type="password"
              v-model="form.Password"
              required
              v-bind:placeholder="$t('请输入密码')"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" style="width:100%;height:50px">{{$t('登录')}}</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<style>
.info_login_content {
  position: absolute;
  width: 400px;
  max-width: 100%;
  right: 15%;
  top: 20%;
  z-index: 2;
  font-weight: bold;
  padding: 10px;
}
@media (max-width: 768px) {
  .info_login_content {
    right: 0%;
  }
}
@media (max-height: 620px) {
  .info_login_content {
    top: 0%;
  }
  #info_login_content_title {
    visibility: hidden;
  }
}
.info_login_content_box {
  height: 350px;
  background-color: white;
  border-radius: 5%;
  padding: 40px;
}
</style>

<script>
import { BaseUrl } from '../../assets/utils/config';
export default {
  data: function() {
    return {
      show: 1,
      startBackThread: null,
      pics:[],
      form: {
        UserName: "",
        Password: ""
      },
      projectData: this.projectData=this.$store.state.projectData||{}
    };
  },
  watch:{
    "$store.state.projectData":function(){
      this.projectData=this.$store.state.projectData;
    }
  },
  created:function(){
    var reload=this.$route.query.reload;
    if(reload){
      window.location.href=window.location.href.replace("?reload=true","");
      window.location.reload();
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // alert(JSON.stringify(this.form));
      var data = JSON.stringify(this.form);
      this.$ksajax.ksPostData("/api/smart/login", data, null, success => {
        this.$cookies.set("token", success.Token, 1);
        this.$store.commit("setLogin", this.form);
        this.$cookies.set("username", this.form.UserName);
          if(this.$route.path.toLowerCase()=="/info/home"){
             this.$cookies.set("ksCustom",true);
             this.$router.push("/home");
          }else{
             this.$cookies.set("ksCustom",false);
            this.$router.push("/home");
          }
        
      });
    }
  },
  mounted: function() {
    this.$nextTick(() => {
      // var ele = document.getElementById("info_menu");
      // if (ele) {
      //   ele.style.backgroundColor = "#33333300";
      // }
      // // document.getElementById("info_footer").style.position = "relative";
    });
      this.pics=[
        `${BaseUrl}/img/newhome/BJ002.png`,
        `${BaseUrl}/img/newhome/BJ003.png`,
        `${BaseUrl}/img/newhome/BJ001.png`,
      ]
  },
  destroyed: function() {}
};
</script>
