<template>
  <div style="width: 100%; height: 100%;overflow: auto;" class="">
    <el-drawer v-if="isDrawer"
      :visible.sync="drawer"
      :direction="direction"
       :with-header="false"
      size="200px"
      style="overflow:auto;height:100%"
    >
         <el-menu
      default-active="2"
       class="el-menu-vertical-demo"
      background-color="#1d1e23"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="isCollapse"
      :router="true"
      style="border: none; height: 100%;"
    >
    <template  v-for="item in menu">
      <el-menu-item v-if="item.Sons.length==0" :key="item.Sid" :index="item.Path">
         <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
      </el-menu-item>
      <el-submenu v-else  :key="item.Sid" :index="item.Path">
        <template slot="title">
          <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
        </template>
        <el-menu-item v-for="sub in item.Sons" :key="sub.Sid" :index="sub.Path">{{sub.Name}}</el-menu-item>
      </el-submenu>
    </template>
     
    </el-menu>
    </el-drawer>
    <el-menu
      v-else
      default-active="0200001"
       class="el-menu-vertical-demo"
      background-color="#1d1e23"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="isCollapse"
      :router="true"
      style="border: none; height: 100%;"
    >
    <template  v-for="item in menu">
      <el-menu-item v-if="item.Sons.length==0" :key="item.Sid" :index="item.Path">
         <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
      </el-menu-item>
      <el-submenu v-else  :key="item.Sid" :index="item.Path">
        <template slot="title">
          <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
        </template>
        <el-menu-item v-for="sub in item.Sons" :key="sub.Sid" :index="sub.Path">{{sub.Name}}</el-menu-item>
      </el-submenu>
    </template>
     
    </el-menu>
  </div>
</template>
<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-drawer{
    height: 100%;
    overflow: auto;
  }
</style>
<script>
import config from "@/assets/utils/config";
export default {
  data: function () {
    return {
      baseUrl:config.BaseUrl,
      items: [],
      direction: "ltr",
      drawer: false,
      isDrawer: false,
      isCollapse:false,
      menu:[]
    };
  },
  watch:{
    "$store.state.menu":function(){
      this.init();
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
     this.init();
    });
    this.init();
  },
  methods: {
    init(){
      if(window.document.body.clientWidth<=1024){
        this.isDrawer=true;
      }else{
        this.isDrawer=false;
      }
      var m = this.$store.getters.getSonMenu("0");
      //添加系统设置菜单
      var set={
        Name: this.$t("系统设置"),
        Order: -10,
        Path: "/admin/setting/password",
        Pic: `${this.baseUrl}/img/newhome/ic_hl.png`,
        Sid: "020000",
        Sons:[
           {
              Path: "/admin/setting/password",
              Name: this.$t("修改密码"),
              Sid: "0200001",
            },
            {
              Path: "/admin/setting/broadset",
              Name: this.$t("广播服务器"),
              Sid: "0200002",
            }
        ]
      };
      var alarm={
        Name: this.$t("报警设置"),
        Order: -10,
        Path: "/admin/setting/alarmer",
        Pic: `${this.baseUrl}/img/newhome/ic_hl.png`,
        Sid: "0100001",
        Sons:[
            {
              Path: "/admin/setting/alarmer",
              Name: this.$t("报警接收人"),
              Sid: "01000011",
            },
            {
              Path: "/admin/setting/alarms",
              Name: this.$t("告警信息"),
              Sid: "01000012",
            },
            {
              Path: "/admin/setting/partment",
              Name: this.$t("部门管理"),
              Sid: "01000013",
            }
        ]
      };
      if(m.filter(x=>x.Sid=='0100001').length==0){
        m.push(alarm);
        m.push(set);
      }
      m.forEach((x) => {
        x.active = false;
      });
      this.menu = m.filter(x=>x.Path.indexOf("http:")==-1);
    },
    action() {
      this.drawer = !this.drawer;
      if(!this.isDrawer){
      this.isCollapse=!this.isCollapse;
      }
    },
  },
};
</script>