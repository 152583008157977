<template>
  <div class="nhome_main ksflexcol nmain">
    <div class="nhome_main_header" style="width: 100%">
      <ksmenu :tran="true"></ksmenu>
    </div>
    <div
      class="nhome_main_center"
      style="flex: 1; width: 100%; padding-top: 4%"
    >
      <div id="nhome_main_center_left">
         <homeBox v-for="item in side" :key="item.index"
         :class="item.cls" :box="{start:item.color.start,end:item.color.end,pic:item.color.pic,title:item.title,path:item.path}"></homeBox>
      </div>
      <div id="nhome_main_center_right">
         <homeBox v-for="item in menu" :key="item.index"
         :class="item.cls" :box="{start:item.color.start,end:item.color.end,pic:item.color.pic,title:item.title,path:item.path}"></homeBox>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>
<style scoped>
.nhome_main_center{
  position: relative;
}
#nhome_main_center_left {
  float: left;
  width: 28.5%;
  position: absolute;
  left: 0;
}
#nhome_main_center_right {
  float: left;
  width: 71.5%;
  position: absolute;
  left:30.5%;
}
@media (max-width: 960px) {
  #nhome_main_center_left {
    width: 100%;
    position: relative;
  }
  #nhome_main_center_left>div{
    width: 33.3333%;
    float: left;
  }
  #nhome_main_center_right {
    position: relative;
    width: 100%;
    left:0
  }
}
</style>
<script>
import homeBox from "../components/homeBox1";
import ksmenu from "../components/ksmenu1";
import config from "../assets/utils/config"
export default {
  components: { homeBox, ksmenu },
  data() {
    return {
      baseUrl:config.BaseUrl,
      test:{
          path: "/Admin/Cam",
          start: "#759DD8",
          end: "#759DD8",
          title:"test",
          pic: `${this.baseUrl}/img/newhome/ic_spjk.png`,
        },
      lang: "zh",
      menu: [],
      side:[],
      userName: this.$cookies.get("username"),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "$store.state.menu": function () {
      this.init();
    },
  },
  methods: {
    getColors(path) {
      var array = [
        {
          path: "/Admin/Cam",
          start: "#1247D1",
          end: "#0335A6",
          pic: `${this.baseUrl}/nicon/video.png`,
        },
        {
          path: "/Admin/Broad",
          start: "#FBB166",
          end: "#F76C41",
          pic: `${this.baseUrl}/nicon/broad.png`,
        },
        {
          path: "/Admin/Stand",
          start: "#496DEF",
          end: "#1536B0",
          pic: `${this.baseUrl}/nicon/lamp.png`,
        },
        {
          path: "/Admin/Light",
          start: "#1247D1",
          end: "#0335A6",
          pic: `${this.baseUrl}/nicon/light.png`,
        },
        {
          path: "/Admin/Sensor",
          start: "#65DD6F",
          end: "#3EA844",
          pic: `${this.baseUrl}/nicon/env.png`,
        },
        {
          path: "/Admin/Led",
          start: "#1C81D9",
          end: "#1362E5",
          pic: `${this.baseUrl}/nicon/infomation.png`,
        },
        {
          path: "/Admin/OView",
          start: "#1C81D9",
          end: "#1362E5",
          pic: `${this.baseUrl}/nicon/data.png`,
        },
        {
          path: "/Admin/Wifi",
          start: "#1C81D9",
          end: "#1362E5",
          pic: `${this.baseUrl}/nicon/wifi.png`,
        },
        {
          path: "/Admin/Water",
          start: "#1C81D9",
          end: "#1362E5",
          pic: `${this.baseUrl}/nicon/water.png`,
        },
        {
          path: "/Admin/Conver",
          start: "#1247D1",
          end: "#0335A6",
          pic: `${this.baseUrl}/nicon/cover.png`,
        },
        {
          path: "/Admin/Car",
          start: "#3AC4F5",
          end: "#218FF0",
          pic: `${this.baseUrl}/nicon/car.png`,
        },
        {
          path: "/Admin/Alarms",
          start: "#F55754",
          end: "#F44647",
          pic: `${this.baseUrl}/nicon/call.png`,
        },
        {
          path: "/Admin/Face",
          start: "#1247D1",
          end: "#0335A6",
          pic: `${this.baseUrl}/nicon/face.png`,
        },
        {
          path: "/Home",
          start: "#686AF4",
          end: "#3C3CC0",
          pic: `${this.baseUrl}/nicon/ic_hl.png`,
        },
        {
          path: "/Admin/XiXun",
          start: "#55A658",
          end: "#55A658",
          pic: `${this.baseUrl}/nicon/infomation.png`,
        },
        {
          path: "/Admin/Hydrant",
          start: "#DF4C56",
          end: "#DF4C56",
          pic: `${this.baseUrl}/nicon/fire.png`,
        },
        {
          path: "/Admin/Nova",
          start: "#FBB166",
          end: "#F76C41",
          pic: `${this.baseUrl}/nicon/infomation.png`,
        },
        {
          path: "/Admin/NovaBroad",
          start: "#D67048",
          end: "#D67048",
          pic: `${this.baseUrl}/nicon/broad.png`,
        },
        {
          path: "/Admin/NwLed",
          start: "#55A658",
          end: "#55A658",
          pic: `${this.baseUrl}/nicon/infomation.png`,
        },
      ];
      var obj = array.find((x) => x.path.toLowerCase() == path.toLowerCase());
      if (obj) {
        return {
          color: obj,
          random: false,
        };
      } else {
        return {
          color: array[Math.round(Math.random() * 18)],
          random: true,
        };
      }
    },
    init() {
      var m = this.$store.getters.getSonMenu("0");
      var side=[];
      var data = [];
      // var x1s=['029','021','']
      for(var i=0;i<m.length;i++){
        var isSider=false;
        var cls="homex5";
        if(m[i].Sid=='029'){
          cls='homex1';
          isSider=true;
        }
        var obj = this.getColors(m[i].Path);
        if (obj.random || !obj.color.pic) {
          obj.color.pic = m[i].Pic;
        }
        obj.cls = cls;
        obj.title = m[i].Name;
        obj.index = i;
        obj.path = m[i].Path;
        if (obj.path.toLowerCase() == "/home") {
          obj.path = "/admin/nhome";
        }
        if(!isSider){
          data.push(obj);
        }else{
          side.push(obj);
        }
      }
      var stand={
         color:{
            path: "/Admin/NwLed",
          start: "#1247D1",
          end: "#0335A6",
          pic: `${this.baseUrl}/img/newhome/ic_xxfb.png`,
         },
         cls:"homex1",
         title:this.$t('基础信息'),
         index:'012'
      }
      var more={
         color:{
            path: "/Admin/NwLed",
          start: "#3AC4F5",
          end: "#218FF0",
          pic: `${this.baseUrl}/img/newhome/ic_xxfb.png`,
         },
         cls:"homex1",
         title:this.$t('更多功能'),
         index:'013'
      }
      side.push(stand);
      side.push(more);
      this.side=side;
      this.menu = data;
      // var j = 1;
      // var x2 = [1, 6, 9, 10, 13, 16];
      // for (var i = 0; i < m.length; i++) {
      //   var cls = "homex1";
      //   if (x2.indexOf(j) != -1) {
      //     cls = "homex1";
      //   }
      //   var obj = this.getColors(m[i].Path);
      //   if (obj.random || !obj.color.pic) {
      //     obj.color.pic = m[i].Pic;
      //   }
      //   obj.cls = cls;
      //   obj.title = m[i].Name;
      //   obj.index = i;
      //   obj.path = m[i].Path;
      //   if (obj.path.toLowerCase() == "/home") {
      //     obj.path = "/admin/nhome";
      //   }
      //   data.push(obj);
      //   if (j == 18) {
      //     j = 0;
      //   }
      //   j += 1;
      // }
      // var v = data.length % 6;
      // if (v > 0 && v <= 2) {
      //   for (var k = 0; k < v; k++) {
      //     data[data.length - 1 - k].cls = "homex1";
      //   }
      //   for (var u = data.length - 1 - v; u >= 0; u--) {
      //     if (v <= 0) {
      //       break;
      //     }
      //     if (data[u].cls == "homex2") {
      //       data[u].cls = "homex1";
      //       v--;
      //     }
      //   }
      // }
      // if (v >= 3) {
      //   data[data.length - 1].cls = "homex2";
      // }
    },
    logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if (this.$route.path.toLowerCase() == "/admin/nhome") {
        this.$router.push({ path: "/info/home?reload=true" });
      } else {
        this.$router.push({ path: "/info/login?reload=true" });
      }
    },
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    },
  },
};
</script>
<style scoped>
.nhome_main {
  padding: 10px 7%;
  height: 100%;
}
.el-radio-button {
  margin-bottom: 0px !important;
}
</style>