<template>
  <div class="nmain top_main">
    <div id="oview_content" v-loading="loading" style="height: 100%">
      <div style="width: 100%; height: 100%; position: relative">
        <div style="width: 100%; height: 100%;" class="mdhiden">
        <ksmap
          ref="map"
          width="100%"
          :showCenter="false"
          height="100%"
          v-bind:lat="39.9"
          v-bind:lng="116.39"
          :custom="'00e7645eb4b3b06b44ffb364eb4f3f1c'"
          v-bind:zoom="25"
        >
        </ksmap>
        </div>
        <div class="sider_oview">
          <div style="height: 25%" class="oview_box">
            <box :trans="true" :title="$t('设备运行统计')">
              <div
                class="ksflex"
                style="width: 100%; height: 100%"
                v-loading="loadingDevRun"
              >
                <div style="flex:1; height: 100%">
                  <pie ref="devRunChart"></pie>
                </div>
                <div style="width:40%; height: 100%" class="lghiden">
                  <div style="height: 100%" class="ksflexcol">
                    <div style="flex: 1; width: 100%" class="ksflex">
                      <div style="width: 30px" class="themeText">
                        {{ $t("设备总数") }}
                      </div>
                      <div
                        style="width: 30px; flex: 1; padding-left: 10px"
                        class="themeText_total_num"
                      >
                        {{ devRunData.TotalCount }}
                      </div>
                    </div>
                    <div style="flex: 1; width: 100%" class="ksflex">
                      <div
                        style="width: 30px"
                        class="themeText themeText_online"
                      >
                        {{ $t("在线数量") }}
                      </div>
                      <div
                        style="width: 30px; flex: 1; padding-left: 10px"
                        class="themeText_total_num themeText_online"
                      >
                        {{ devRunData.OnlineCount }}
                      </div>
                    </div>
                    <div style="flex: 1; width: 100%" class="ksflex">
                      <div
                        style="width: 30px"
                        class="themeText themeText_offline"
                      >
                        {{ $t("离线数量") }}
                      </div>
                      <div
                        style="width: 30px; flex: 1; padding-left: 10px"
                        class="themeText_total_num themeText_offline"
                      >
                        {{ devRunData.TotalCount - devRunData.OnlineCount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </box>
          </div>
          <div style="height: 35%" class="oview_box">
            <box :trans="true" :title="$t('设备数量统计')">
              <bar v-loading="loadingDevCount" ref="devCountChar"></bar>
            </box>
          </div>
          <div style="height: 40%" class="oview_box">
            <box
              :trans="true"
              :title="$t('设备告警统计')"
              :action="alarmAction"
              @actionEvent="alarmActionEvent"
            >
              <div style="width: 100%; height: 100%" class="ksflexcol">
                <div style="flex: 1; width: 100%">
                  <linebar
                    v-loading="loadingDevAlarm"
                    ref="devAlarmChar"
                  ></linebar>
                </div>

                <div
                  style="width: 100%; height: 60px; padding: 10px 0 0 10px"
                  class="ksflex"
                >
                  <div style="flex: 1">
                    <div style="color: #969696" class="themeText">  {{ $t("告警总数") }}</div>
                    <div
                      style="color: #969696; text-align: center"
                      class="themeText_total_num"
                    >
                      {{ devAlarmData.TotalCount || 0 }}
                    </div>
                  </div>
                  <div style="flex: 1">
                    <div style="color: #3197fa" class="themeText"> {{ $t("处理数量") }}</div>
                    <div
                      style="color: #3197fa; text-align: center"
                      class="themeText_total_num"
                    >
                      {{ devAlarmData.DisposeCount }}
                    </div>
                  </div>
                  <div style="flex: 1">
                    <div style="color: #ffb744" class="themeText">
                      {{ $t("未处理数量") }}
                    </div>
                    <div
                      style="color: #ffb744; text-align: center"
                      class="themeText_total_num"
                    >
                      {{ devAlarmData.TotalCount - devAlarmData.DisposeCount }}
                    </div>
                  </div>
                </div>
              </div>
            </box>
          </div>
        </div>
        <div class="sider_oview sider_oview_right" >
          <div style="height: 30%" class="oview_box">
            <box
              :trans="true"
              :title="$t('视频监控')"
              :action="videoAction"
              @actionEvent="videoActionEvent"
            >
              <div
                style="height: 100%; position: relative"
                v-if="devVideosData && devVideosData.length != 0 && hasVideoOnline"
              >
                <div style="height: 100%">
                  <netvideo
                    ref="netplay"
                    :unshowaction="true"
                    style="padding-top: 10px"
                    :playdata="playdata"
                  ></netvideo>
                </div>
                <div
                  style="
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40%;
                    height: 100%;
                    padding: 10px 5px 0 5px;
                  "
                  v-show="chooseVideo"
                >
                  <div
                    style="
                      border: 1px solid #3274b8;
                      height: 100%;
                      overflow: auto;
                      background-color: #022c7d;
                    "
                    class="hiden_scroll"
                  >
                    <div
                      class="themeText"
                      style="
                        padding: 5px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow-x: hidden;
                        cursor: pointer;
                      "
                      v-for="item in devVideosData"
                      :key="item.Sid"
                      @click="playVidoesBySid(item.Sid)"
                    >
                      {{ item.DeviceName }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else style="height: 100%; padding-top: 15%">
                <div>
                  <div style="text-align: center">
                    <img :src="baseUrl+'/nicon/novideo.png'" />
                  </div>
                  <div style="text-align: center">
                    <span> {{ $t("暂无视频") }}</span>
                  </div>
                </div>
              </div>
            </box>
          </div>
          <div style="height: 45%" class="oview_box">
            <box
              :trans="true"
              :title="$t('告警监测')"
              v-loading="loadingAlarmAction"
            >
              <div style="height: 70%; border-bottom: 1px dashed #3274b8">
                <div style="height: 100%" class="ksflexcol">
                  <div style="width: 100%; flex: 1">
                    <div
                      v-if="!currentAlarm.show"
                      style="
                        height: 100%;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                      "
                    >
                      <img
                        :src="baseUrl+'/nicon/stand.png'"
                        style="height: 80%"
                      />
                    </div>
                    <div v-else style="width: 100%; height: 100%">
                      <component
                        v-bind:is="currentComponent"
                        :sid="currentAlarm.deviceSid"
                        :stringvalue="currentAlarm.stringValue"
                        :objvalue="currentAlarm.objectValue"
                      ></component>
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      padding: 0 10px 10px 10px;
                      text-align: left;
                    "
                    class="themeText themeText_offline ksflex"
                  >
                    <div style="flex: 1">{{ $t("警情") }}:{{ currentAlarm.text }}</div>
                    <div style="width: 60px">
                      <div class="thembutton" @click="actionAlarm">{{ $t("处理") }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 30%;overflow: auto;background-color: #3197fa; margin:10px 5px 0 5px" class="ksflex hiden_scroll">
                <div style="flex: 1; height: 100%; ">
                  <div style="height: 100%">
                    <div
                      style="text-align: left; padding-left: 10px"
                      class="themeText"
                    >
                      <div v-if="!currentAlarm.AlarmDevice">{{ $t("暂无详情") }}</div>
                      <div v-else style="overflow: auto" class="hiden_scroll">
                        <div class="ksflex">
                          <div style="width: 30%">{{ $t("设备名称") }}</div>
                          <div style="flex: 1">
                            {{ currentAlarm.AlarmDevice.DeviceName }}
                          </div>
                        </div>
                        <div class="ksflex">
                          <div style="width: 30%">{{ $t("告警时间") }}</div>
                          <div style="flex: 1">
                            {{ currentAlarm.AlarmTime }}
                          </div>
                        </div>
                        <div class="ksflex">
                          <div style="width: 30%">{{ $t("人员通知") }}</div>
                          <div style="flex: 1">
                            <div
                              v-if="
                                !currentAlarm.AlarmNotifies ||
                                currentAlarm.AlarmNotifies.length == 0
                              "
                            >
                              {{ $t("无通知人员") }}
                            </div>
                            <div
                              v-for="item in currentAlarm.AlarmNotifies"
                              :key="`${item.Account}${item.NotifyWay}`"
                            >
                              <div>
                                {{ item.DisplayName }}:{{ item.Account }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </box>
          </div>
          <div style="height: 25%" class="oview_box">
            <box
              :trans="true"
              :title="$t('设备告警')"
              :action="$t('刷新')"
              @actionEvent="getAlarms"
            >
              <div
                v-loading="loadingAlarms"
                style="height: 100%; padding: 15px 15px 0 15px"
              >
                <el-table
                  :data="alarms"
                  :fit="false"
                  :stripe="true"
                  style="width: 100%; background-color: transparent"
                  class="smtable"
                  @row-click="rowClick"
                >
                  <el-table-column
                    prop="DeviceName"
                    :label="$t('告警设备')"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="AlarmTypeText"
                    :label="$t('告警信息')"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="AlarmTime"
                    :label="$t('告警时间')"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        `${new Date(scope.row.AlarmTime).getHours()}:${new Date(
                          scope.row.AlarmTime
                        ).getMinutes()}`
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="DisposeStatus"
                    :label="$t('处理状态')"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        `${scope.row.DisposeStatus ? $t('已处理') : $t('未处理')}`
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </box>
          </div>
        </div>
        <div
          style="
            position: absolute;
            width: 60%;
            height: 90px;
            bottom: 25px;
            left: 0;
            right: 0;
            margin: auto;
            padding-left: 15px;
            padding-right: 15px;
            z-index: 3;
          "
          class="mdhiden"
        >
          <div
            style="width: 100%; height: 100%; justify-content: flex-end"
            class="ksflex"
          >
            <div
              style="height: 100%; flex: 1"
              class="view_choosedev_ex ksflex"
              ref="view_choosedev_ex"
            >
              <div
                style="
                  height: 100%;
                  width: 15px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                "
              >
                <i class="fa fa-chevron-left" @click="scroll(true)"></i>
              </div>
              <div
                style="
                  height: 100%;
                  flex: 1 1 0%;
                  padding: 0px 10px;
                  align-items: center;
                  text-align: right;
                  justify-content: flex-end;
                  overflow: hidden;
                  white-space: nowrap;
                "
                ref="chooseDevContent"
                id="test"
              >
                <div
                  style="
                    display: inline-block;
                    width: 80px;
                    text-align: center;
                    margin-top: 8px;
                    cursor: pointer;
                  "
                  v-for="item in datas"
                  :key="item.DCategorySid"
                  @click="choose(item.DCategorySid)"
                >
                  <div>
                    <el-image
                      style="height: 50px; width: 50px"
                      :src="item.img"
                    ></el-image>
                  </div>
                  <div class="themeText writemode">
                    {{ $t(item.CategoryName) }}
                  </div>
                </div>
              </div>
              <div
                style="
                  height: 100%;
                  width: 15px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                "
              >
                <i class="fa fa-chevron-right" @click="scroll(false)"></i>
              </div>
            </div>
            <div
              style="
                height: 100%;
                width: 90px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="view_choosedev"
              @click="chooseDev"
            >
              <div style="width: 50%; font-size: 20px">
                {{ choosed ? $t("选择类型") : $t("收起类型") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <b-modal
      ref="modal"
      :title="$t('警情处理') "
      centered
      content-class="ksmod"
      :ok-title="$t('提交')"
      :cancel-title="$t('取消')"
      hide-footer
    >
      <b-form>
        <b-form-group :label="$t('接收人') " label-cols="2">
          <b-form-select text-field="DisplayName" value-field="Sid" :options="recivers" v-model="selectedReciver"></b-form-select>
        </b-form-group>
          <b-form-group>
            <div class="modal-footer">
                   <b-button variant="secondary" v-on:click="hidden">{{$t('取消')}}</b-button>
                   <b-button type="button"  variant="primary" v-on:click='onSubmit'>{{$t('确认')}}</b-button>
            </div>
          </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style scoped>
.oview_box {
  padding-top: 10px;
}
.oc_center_bottom_c {
  height: 100%;
  width: 100%;
}
.oc_center_bottom_c .ksrow {
  height: 50%;
}
.oview_content_c {
  float: left;
  height: 100%;
  padding: 5px;
  width: 33.33%;
}
#oview_content {
  color: white;
  height: 100%;
  height: calc(100% - 105px);
}
@media (max-width: 768px) {
  #main_top_center {
    display: none;
  }
  #main_top_right {
    flex: unset;
  }
  .oview_content_c {
    width: 100%;
  }
  #oview_content_center {
    height: 150%;
  }
  #oview_content_left {
    height: 180%;
  }
}
</style>
<script>
import alarm from "@/components/alarm";
import box from "@/components/box1.vue";
import basic from "@/components/basic";
import config from "@/assets/utils/config";
import topmenu from "@/components/topmenu";
import ksmap from "@/components/map";
import pie from "@/components/chart/pie";
import bar from "@/components/chart/bar";
import linebar from "@/components/chart/line";
import netvideo from "@/components/netvideo";
import water from "../admin/water/components/waterdetail";
import car from "../admin/car/components/cardetail";
import cover from "../admin/conver/components/converdetail";
import hydrant from "../admin/hydrant/components/hydrantdetail";
import sensor from "../admin/sensor/components/sensordetail";
import voice from "../admin/voice/components/voicedetail";
import afr from "../admin/afr/components/afrdetail";
import Box from "../../components/box.vue";
export default {
  watch: {
    "$store.state.alarmHubChange": function () {
      try {
        var data = this.$store.state.alarmHub;
        // if (this.alarmTypes.findIndex((x) => x == data.Type) == -1) {
          if (this.currentAlarm.deviceSid == data.DeviceSid) {
            //如果是当前的报警类型，不处理
            return;
          } else {
            this.getAlarmAction(data.Sid);
          }
        // }
      } catch (err) {
        //
      }
    },
    alarmTrigger: function () {
      if (this.alarmTrigger) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    },
  },
  components: {
    alarm,
    box,
    basic,
    ksmap,
    topmenu,
    pie,
    bar,
    linebar,
    netvideo,
    water,
    car,
    cover,
    hydrant,
    sensor,
    voice,
    afr,

    Box,
  },

  data: function () {
    return {
      baseUrl:config.BaseUrl,
      loadingDevRun: false,
      choosed: true,
      isNeture: config.ISNETURE,
      home:
        this.$cookies.get("ksCustom") == "true"
          ? "/admin/nhome"
          : "/admin/home",
      logopic: require("@/assets/img/cute/logo.png"),
      loading: false,
      datas: [],
      loadingMap: false,
      loadingDevCount: false,
      loadingDevAlarm: false,
      loadingVideos: false,
      loadingAlarms: false,
      loadingAlarmAction: false,
      devVideosData: [],
      devRunData: {},
      devCountData: {},
      devAlarmData: {
        TotalCount: 0,
        DisposeCount: 0,
      },
      devAlarmYear: true,
      alarmAction: "",
      playdata: {},
      chooseVideo: false,
      videoAction: "",
      currentAlarm: {
        text: this.$t("暂无警情"),
        type: null,
        sid: null,
        deviceSid: null,
        show: false,
        actions: [],
        pics: [],
        dataType: "",
        stringValue: null,
        objectValue: {},
        AlarmDevice: null,
        AlarmNotifies: null,
        currentVideo: "",
      },
      alarms: [],
      currentComponent: "water",
      alarmTypes: [0, 1, 2, 44, 83, 86, 24, 25, 26, 27],
      recivers: [],
      selectedReciver:"",
      hasVideoOnline:false
    };
  },
  mounted: function () {
    this.getRecivers();
    this.getMapData();
    this.getDevRun();
    this.getDevCount();
    this.getDevAlarmCount();
    this.getVideos();
    this.getAlarms();
  },
  methods: {
    onSubmit(evt){
      evt.preventDefault();
      var ret={
        Sid:this.currentAlarm.sid,
        DisposerSid:this.selectedReciver,
        Description:this.$t("手动派发")
      }
       this.$ksajax
        .ksPostPromise("/api/alarm/Processing", ret, true).then(()=>{
          this.hidden();
        }).catch(err=>{
          this.$throw(err);
          this.hidden();
        });
    },
    hidden(){
      this.$refs.modal.hide();
    },
    getRecivers(){
      var ret={
        Index:0,
        Count:100
      };
      this.$ksajax
        .ksPostPromise("/api/alarm/GetReceivers", ret, true).then(r=>{
          this.recivers=r.Items;
        });
    },
    rowClick(row) {
      this.getAlarmAction(row.Sid);
    },
    actionAlarm() {
      if(this.recivers.length==0){
        this.$throw(this.$t("请先添加报警接收人"));
      }else if(!this.currentAlarm.sid){
        this.$throw(this.$t("请先选择报警消息"));
      }
      else{
        this.$refs.modal.show();
      }
    },
    getAlarmPics(array) {
      this.currentAlarm.pics = [];
      array.forEach((x) => {
        var url = "";
        var ret = null;
        if (x.type == "0601") {
          url = "/api/led/GetScreen";
          ret = x.sid;
        }
        if (x.type == "0602") {
          url = "/api/led/NovaLedScreenshot";
          ret = [x.serial];
        }
        this.$ksajax
          .ksPostPromise(url, ret, true)
          .then((y) => {
            if (y.forEach) {
              if (y.length != 0) {
                this.currentAlarm.pics.push(y[0]);
              }
            } else {
              this.currentAlarm.pics.push(y);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    setALarmData(data, sid) {
      this.currentAlarm.text = data.AlarmTypeText;
      this.currentAlarm.type = data.AlarmDevice.DCategorySid;
      this.currentAlarm.deviceSid = data.AlarmDevice.Sid;
      this.currentAlarm.sid = sid;
      this.currentAlarm.AlarmNotifies = data.AlarmNotifies;
      this.currentAlarm.AlarmDevice = data.AlarmDevice;
      this.currentAlarm.AlarmTime = data.AlarmTime;
      this.currentAlarm.AlarmType = data.AlarmType;
      // var cam = data.ActionDevices.find((x) => x.Group == 20);
      // if (cam && cam.Data.length != 0) {
      //   this.playVidoesBySid(cam.Data[0].DeviceSid);
      // }
      // var stands=data.ActionDevices.find(x=>x.Group==0);
      // if(stands&&stands.Data.length!=0){
      //   var s=[];
      //   stands.Data.forEach(stand=>{
      //     s.push({
      //       sid:stand.DeviceSid,
      //       type:stand.DeviceTypeSid,
      //       serial:stand.Serial
      //     });
      //     this.getAlarmPics(s);
      //   });
      // }else{
      //   this.currentAlarm.pics=[];
      // }
      var com = this.getCurrentComponent(this.currentAlarm.type);
      if (com) {
        if (this.currentAlarm.type == "001") {
          if (data.AlarmDevice.DTypeSid == "0151") {
            this.currentComponent = com;
            this.currentAlarm.show = true;
            this.currentAlarm.stringValue = data.AlarmData;
          } else {
            this.currentAlarm.show = false;
          }
        } else {
          if (this.currentAlarm.type == "016") {
            this.currentAlarm.stringValue = data.AlarmData;
          }
          else if(this.currentAlarm.type=='005'){
            this.currentAlarm.stringValue=data.AlarmType;
          }
          this.currentAlarm.show = true;
          this.currentComponent = com;
        }
      } else {
        this.currentAlarm.show = false;
      }
      if (this.currentAlarm.type == "001" || this.currentAlarm.type == "015") {
        if (this.currentVideo != this.currentAlarm.deviceSid) {
          // this.playVidoesBySid(this.currentAlarm.deviceSid);
        }
      }
    },
    getCurrentComponent(type) {
      var com = null;
      switch (type) {
        case "005":
          com = "sensor";
          break;
        case "009":
          com = "water";
          break;
        case "010":
          com = "cover";
          break;
        case "011":
          com = "car";
          break;
        case "012":
          com = "hydrant";
          break;
        case "001":
          com = "afr";
          break;
        case "016":
          com = "voice";
          break;
      }
      return com;
    },
    getAlarmAction(sid) {
      this.loadingAlarmAction = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/DeviceAlarmInfo", sid, true)
        .then((data) => {
          this.loadingAlarmAction = false;
          this.setALarmData(data, sid);
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingAlarmAction = false;
        });
    },
    getAlarms() {
      this.loadingAlarms = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/LatestNDeviceAlarms", 15, true)
        .then((data) => {
          this.loadingAlarms = false;
          this.alarms = data;
        })
        .catch((err) => {
          this.loadingAlarms = false;
          this.$throw(err);
        });
    },
    videoActionEvent() {
      this.chooseVideo = !this.chooseVideo;
    },
    getPlayReqData(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
    playVidoesBySid(sid) {
      var device = this.devVideosData.find((x) => x.Sid == sid);
      this.playVidoes(device);
    },
    playVidoes(device) {
      this.loadingVideos = true;
      var req = this.getPlayReqData(device.Sid, "sd", false);
      this.$ksajax
        .ksPostPromise("/api/device/GetPayNet", req, true)
        .then((success) => {
          this.loadingVideos = false;
          this.playdata.data = success;
          this.playdata.isPlay = true;
          this.playdata.device = device;
          this.$refs.netplay.start();
          this.chooseVideo = false;
          this.currentVideo = device.Sid;
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingVideos = false;
        });
    },
    getVideos() {
      this.loadingVideos = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/MonitoringVideos", "", true)
        .then((data) => {
          this.loadingVideos = false;
          this.devVideosData = data;
          if (this.devVideosData.length != 0) {
            this.videoAction = "切换";
          }
          var play = this.devVideosData.find((x) => x.Status == 1);
          if (play) {
            this.playVidoes(play);
            this.hasVideoOnline=true;
          }else{
            this.hasVideoOnline=false;
          }
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingVideos = false;
        });
    },
    alarmActionEvent() {
      if (this.devAlarmYear) {
        this.getDevAlarmCountDay();
      } else {
        this.getDevAlarmCount();
      }
    },
    getDevAlarmCountDay() {
      this.loadingDevAlarm = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/DeviceMonthAlarm", "", true)
        .then((data) => {
          this.loadingDevAlarm = false;
          var alarm = {
            name: this.$t("告警"),
            data: [],
            color: "#FF48487f",
          };
          var action = {
            name:this.$t("处理") ,
            data: [],
            color: "#3197FA7F",
          };
          var xdata = [];
          data.DayTrends.forEach((x) => {
            xdata.push(`${x.Month}.${x.Day}`);
            alarm.data.push(x.AlarmCount);
            action.data.push(x.DisposeCount);
          });
          this.devAlarmData = {
            xdata: xdata,
            data: [alarm, action],
            color: ["#FF4848", "#3197FA"],
            TotalCount: data.TotalCount,
            DisposeCount: data.DisposeCount,
          };
          this.devAlarmData.TotalCount = data.TotalCount;
          this.devAlarmData.DisposeCount = data.DisposeCount;
          this.devAlarmData.color = ["#FFB744", "#3197FA"];
          this.devAlarmData.data = [alarm, action];
          this.devAlarmData.xdata = xdata;
          this.$refs.devAlarmChar.setAndInit(
            this.devAlarmData.data,
            null,
            this.devAlarmData.color,
            null,
            this.devAlarmData.xdata
          );
          this.alarmAction = this.$t("切换年统计") ;
          this.devAlarmYear = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingDevAlarm = false;
        });
    },
    getDevAlarmCount() {
      this.loadingDevAlarm = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/DeviceYearAlarm", "", true)
        .then((data) => {
          this.loadingDevAlarm = false;
          var alarm = {
            name:  this.$t("告警") ,
            data: [],
            color: "#FF48487f",
          };
          var action = {
            name: this.$t("处理") ,
            data: [],
            color: "#3197FA7F",
          };
          var xdata = [];
          data.MonthTrends.forEach((x) => {
            xdata.push(`${x.Year}.${x.Month}`);
            alarm.data.push(x.AlarmCount);
            action.data.push(x.DisposeCount);
          });
          this.devAlarmData = {
            xdata: xdata,
            data: [alarm, action],
            color: ["#FF4848", "#3197FA"],
            TotalCount: data.TotalCount,
            DisposeCount: data.DisposeCount,
          };
          this.$refs.devAlarmChar.setAndInit(
            this.devAlarmData.data,
            null,
            this.devAlarmData.color,
            null,
            this.devAlarmData.xdata
          );
          this.alarmAction =  this.$t("切换月统计") ;
          this.devAlarmYear = true;
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingDevAlarm = false;
        });
    },
    getDevCount() {
      this.loadingDevCount = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/DeviceStatistics", "", true)
        .then((data) => {
          this.loadingDevCount = false;
          var online = {
            name: this.$t("在线") ,
            data: [],
          };
          var offline = {
            name: this.$t("离线")  ,
            data: [],
          };
          var xdata = [];
          data.forEach((x) => {
            xdata.push(this.$t(x.CategoryName).toLocaleUpperCase());
            online.data.push(x.OnlineCount);
            offline.data.push(x.TotalCount - x.OnlineCount);
          });
          this.devCountData = {
            xdata: xdata,
            data: [offline, online],
            color: ["#FFB744", "#3197FA"],
          };
          this.$refs.devCountChar.setAndInit(
            this.devCountData.data,
            null,
            this.devCountData.color,
            null,
            this.devCountData.xdata
          );
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingDevCount = false;
        });
    },
    getDevRun() {
      this.loadingDevRun = true;
      this.$ksajax
        .ksPostPromise("/api/Dashboard/RunningStatus", "", true)
        .then((data) => {
          this.loadingDevRun = false;
          data.title = {
            // text:"设备运行状态",
            // subtext:"From DataCenter",
            // left:"center",
            show: false,
          };
          (data.data = [
            {
              value: data.TotalCount - data.OnlineCount,
              name: this.$t("离线"),
            },
            {
              value: data.OnlineCount,
              name: this.$t("在线"),
            },
          ]),
            (data.color = ["#FFB744", "#3197FA"]);
          this.devRunData = data;
          this.$refs.devRunChart.setAndInit(data.data, null, data.color, null);
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingDevRun = false;
        });
    },
    getMapData() {
      if (this.$store.state.devices.length == 0) {
        this.loadingMap = true;
        this.$ksajax
          .ksPostPromise("/api/info/GetDevice", "", true)
          .then((data) => {
            this.$store.state.devices = data;
            this.loadingMap = false;
            data.forEach((x) => {
              x.active = true;
              x.img = `${this.baseUrl}/nicon/choose/${x.DCategorySid}.png`;
              x.Status = x.DCategorySid == "002" ? (x.Status = 1) : x.Status;
            });
            this.datas = data.filter((x) => !x.DCategorySid.startsWith("1"));
            this.addAllDevice();
          })
          .catch((err) => {
            this.$throw(err, this);
            this.loadingMap = false;
          });
      } else {
        this.datas = this.$store.state.devices.filter(
          (x) => !x.DCategorySid.startsWith("1")
        );
        this.datas.forEach((x) => {
          x.active = true;
          x.img = `${this.baseUrl}/nicon/choose/${x.DCategorySid}.png`;
          x.Status = x.DCategorySid == "002" ? (x.Status = 1) : x.Status;
        });
        this.addAllDevice();
      }
    },
    chooseDev() {
      this.choosed = !this.choosed;
      this.$refs.view_choosedev_ex.classList.remove("view_choosedev_ex_close");
      this.$refs.view_choosedev_ex.classList.remove("view_choosedev_ex_open");
      this.$refs.view_choosedev_ex.classList.remove("show");
      this.$refs.view_choosedev_ex.classList.remove("hidden_v");
      if (this.choosed) {
        this.$refs.view_choosedev_ex.classList.add("view_choosedev_ex_open");
        this.$refs.view_choosedev_ex.classList.add("show");
      } else {
        this.$refs.view_choosedev_ex.classList.add("view_choosedev_ex_close");
        this.$refs.view_choosedev_ex.classList.add("hidden_v");
      }
    },
    choose(sid) {
      this.datas.forEach((x) => {
        if (x.DCategorySid == sid) {
          x.active = !x.active;
          x.CategoryName = x.CategoryName + " ";
          if (x.active) {
            x.img = `${this.baseUrl}/nicon/choose/${x.DCategorySid}.png`;
          } else {
            x.img = `${this.baseUrl}/nicon/choose/${x.DCategorySid}_c.png`;
          }
        }
      });
      this.chooseDevbySid();
    },
    addAllDevice() {
      this.datas.forEach((element) => {
        var datas = [];
        element.List.forEach((x) => {
          datas.push({
            icon: `${this.baseUrl}/nicon/map/${element.DCategorySid}${
              x.Status == 0 ? "_o" : ""
            }.png`,
            lat: x.Lat,
            lng: x.Lng,
            title: x.DeviceName,
            sid: x.Sid,
            serial: x.Serial,
            url: this.getUrl(element.DCategorySid, x.Sid),
            draggable: false,
          });
        });
        var iconSize = {
          w: 26,
          h: 40,
        };
        this.$refs.map.addDevice(datas, element.DCategorySid, iconSize);
      });
      this.$refs.map.setFilter();
    },
    chooseDevbySid() {
      //删除地图的设备
      var array = this.datas.filter((x) => x.active == true);
      var sp = [];
      array.forEach((y) => {
        sp.push(y.DCategorySid);
      });
      this.$refs.map.fresh(sp);
      this.$refs.map.setFilter();
    },
    scroll(left) {
      var scroll = this.$refs.chooseDevContent.scrollWidth;
      var width = this.$refs.chooseDevContent.clientWidth;
      if (left) {
        if (
          this.$refs.chooseDevContent.scrollLeft + 100 >
          scroll - width + 100
        ) {
          this.$refs.chooseDevContent.scrollTo(
            this.$refs.chooseDevContent.scrollLeft,
            0
          );
        } else {
          this.$refs.chooseDevContent.scrollTo(
            this.$refs.chooseDevContent.scrollLeft + 100,
            0
          );
        }
      } else {
        if (this.$refs.chooseDevContent.scrollLeft - 100 <= 0) {
          this.$refs.chooseDevContent.scrollTo(0, 0);
        } else {
          this.$refs.chooseDevContent.scrollTo(
            this.$refs.chooseDevContent.scrollLeft - 100,
            0
          );
        }
      }
    },
    getUrl(type, sid) {
      var url = null;
      switch (type) {
        case "001":
          url = "/Admin/Cam/NetCam";
          break;
        case "002":
          url = `/admin/stand/Broaddetail?sid=${sid}`;
          break;
        case "003":
          url = "/Admin/Light/Geteway";
          break;
        case "004":
          url = "/Admin/Light/LControl";
          break;
        case "005":
          url = `/admin/stand/Sensordetail?sid=${sid}`;
          break;
        case "006":
          url = `/admin/stand/Leddetail?sid=${sid}`;
          break;
        case "007":
          url = "/Admin/Wifi/Ac";
          break;
        case "008":
          url = "/Admin/Wifi/AP";
          break;
        case "009":
          url = `/admin/water/detail?sid=${sid}`;
          break;
        case "010":
          url = `/admin/conver/detail?sid=${sid}`;
          break;
        case "011":
          url = `/admin/car/detail?sid=${sid}`;
          break;
        case "101":
          break;
        case "102":
          break;
        case "103":
          url = "/admin/stand/standinfo?sid=" + sid;
          break;
      }
      if (!url) {
        url = "/admin/stand/Commondevice?sid=" + sid;
      }
      return url;
    },
  },
};
</script>
