<template>
  <div style="width: 100%; height: 100%; position: relative" ref="rate">
    <div
      ref="barchart"
      style="width: 100%; height: 100%; position: relative"
    ></div>
    <div class="rate_inner" :style="style">
        <div>{{rate}}%</div>
        <div>{{name}}</div>
    </div>
  </div>
</template>
<style scoped>
.rate_inner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: flex;
  align-content: center;    
    flex-direction: column;
    justify-content: center;

}
.rate_inner>div{
    font-size: 12px;
    color: white;
    text-align: center;
}
</style>
<script>
export default {
  data: function () {
    return {
      chart: null,
      data: null,
      legend: null,
      color: null,
      emphasis: null,
      name:null,
      rate:0,
      style: {
        width: "0px",
        height: "0px",
        "background-color": "white",
        "border-radius": "100%",
      },
    };
  },
  methods: {
    setInner() {
      var width = this.$refs.rate.clientWidth;
      var height = this.$refs.rate.clientHeight;
      if(width>height){
          width=height;
      }else{
          height=width;
      }
      this.style.width=this.style.height=`${width*0.7}px`;
    },
    getOption() {
      var opt = {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
          position: ["50%", "50%"],
          show:false
        },
        legend: this.legend,
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "3%",
          containLabel: true,
        },
        series: [
          {
            hoverAnimation: false,
            type: "pie",
            radius: ["85%", "100%"],
            center: ["50%", "50%"],
            data: this.data,
            color: this.color,
            label: {
              position: "center",
              alignTo: "none",
              bleedMargin: 0,
              formatter: "{d}",
              show: false,
            },
            labelLine: {
              length: 10,
              length2: 10,
            },
          },
        ],
      };
      //   opt.title = this.pietitle;
      return opt;
    },
    getTyped(type){
        var ret={};
        switch(type){
            case 0:
                //正常
                ret.chartColor="#3197FA"
                ret.innerColor="#3197FA"
                break;
            case 1:
                //警告
                ret.chartColor="#FFB744"
                ret.innerColor="#FFB744"
                break;
            case 2:
                //警告
                ret.chartColor="#FF4848"
                ret.innerColor="#FF4848"
                break;
            
        }
        return ret;
    },
    setAndInit(data1,data2,name,type) {
      this.data = [
          {
              name:"",
              value:data1
          },
          {
              name:"",
              value:data2
          }
      ];
      var typed=this.getTyped(type);
      this.color = [typed.chartColor,typed.chartColor];
      this.style['background-color']=typed.innerColor;
      this.name=name;
      this.rate= parseInt(data1/(data1+data2)*100);
      this.init();
    },
    init() {
      if (!this.chart) {
        this.chart = window.echarts.init(this.$refs.barchart);
      }
      this.setInner();
      var obj = this.getOption();
      this.chart.setOption(obj);
      window.charts=window.charts||[];
      window.charts.push(this.chart);
      window.addEventListener("resize",()=>{
        this.chart.resize();
      });
      return this.chart;
    },
  },
};
</script>