<template>
  <div v-show="menus && menus.lenght != 0">
    <router-link
      v-for="item in menus"
      :key="item.Sid"
      :to="item.Path"
      class="menu_sub"
    >
      <span class="menu_sub_item">{{ item.Name }}</span>
    </router-link>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      menus: [],
    };
  },
  watch: {
    "$store.state.currentUrl": function () {
      this.setSubMenu(this.$store.state.currentUrl);
    },
  },
  mounted: function () {
    this.setSubMenu(this.$store.state.currentUrl);
  },
  methods: {
    setSubMenu(path) {
      var m = this.$store.getters.getSonMenuPath(path);
      this.menus = m.Sons;
      if (path.toLocaleLowerCase() == "/admin/setting") {
        this.menus = [
          {
            Path: "/admin/setting/password",
            Name: this.$t("修改密码"),
            Sid: "0200001",
          },
          {
            Path: "/admin/setting/broadset",
            Name: this.$t("广播服务器"),
            Sid: "0200002",
          },
        ];
      } else if (path.toLocaleLowerCase() == "/admin/alarm") {
        this.menus = [
          {
            Path: "/admin/alarm/alarmer",
            Name: this.$t("报警接收人"),
            Sid: "01000011",
          },
          {
            Path: "/admin/alarm/alarms",
            Name: this.$t("告警信息"),
            Sid: "01000012",
          },
          {
            Path: "/admin/alarm/partment",
            Name: this.$t("部门管理"),
            Sid: "01000013",
          },
        ];
      }
      this.$emit("submenuLoaded", this.menus, path);
    },
  },
};
</script>