import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';
import Vue from 'vue';
import App from './App.vue';
Vue.config.productionTip = false;
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "animate.css";
import "./assets/css/box.css";
import 'font-awesome/css/font-awesome.css';
// import router from "./router"
// import "kindeditor";
// import "kindeditor/themes/default/default.css";
import "../public/cs/gobal.css";
import VueI18n from 'vue-i18n';
import http from '@/assets/utils/kshttp.js';
import cookies from '@/assets/utils/kscookie';
import routeAction from '@/assets/utils/routeAction.js';
import store from './store';
import VueDraggableResizable from "vue-draggable-resizable";
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
Vue.component("vue-draggable-resizable", VueDraggableResizable);

import { 
  Badge, Loading, Button, Table, TableColumn, TimeSelect, TimePicker, DatePicker, Upload, Steps, Step, Select, Option, Carousel, CarouselItem, Switch, Slider, Checkbox, Pagination,
  Form,
  Row, Col,
  FormItem,
  MessageBox, CheckboxGroup, InputNumber, Input,
  Timeline, TimelineItem, Card,
  Message, ColorPicker, RadioGroup, RadioButton, Radio, Dropdown, DropdownItem, DropdownMenu, Image,Tabs,TabPane,
  Submenu, Menu, MenuItem, MenuItemGroup, Drawer,Tooltip,Dialog
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Submenu);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Image);
Vue.use(Input);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Pagination);
Vue.use(CheckboxGroup);
Vue.use(InputNumber);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(Carousel);
Vue.use(Switch);
Vue.use(CarouselItem);
Vue.use(Option);
Vue.use(Steps);
Vue.use(Select);
Vue.use(Step);
Vue.use(ColorPicker);
Vue.use(Badge);
Vue.use(Badge);
Vue.use(Loading);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Form);
Vue.use(FormItem);
Vue.prototype.$cookies = cookies
Vue.prototype.$ksajax = http
Vue.use(VueI18n)
Vue.use(store)
Vue.use(Loading.directive);
Vue.use(Drawer);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = MessageBox.Notification;
Vue.prototype.$message = Message;
Vue.config.productionTip = false
const i18n = new VueI18n({
  locale: "zh",
  messages: {
    zh: require("./lan/zh-cn"),
    en: require("./lan/us-en")
  }
});
Vue.use(BootstrapVue);

// 简单跑马灯
import EasySlider from 'vue-easy-slider';
Vue.use(EasySlider);

// 图片延迟加载
import VueLazyload from 'vue-lazyload';

import config from "./assets/utils/config";
let getRouter = function () {
  if (config.ISNETURE) {
    return require("./nrouter");
  } else {
    if (config.Theme == 1) {
      return require("./router1");
    } else {
      return require("./router");
    }
  }
}
if (config.Theme == 1) {
   require("./assets/utils/theme");
}
let router = getRouter().default;
Vue.use(VueLazyload);

//系统错误捕获
const errorHandler = (error, vm) => {
  console.error('抛出全局异常', vm, error);
  store.commit("seterrorMsg", error);
}

Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error, vue) => errorHandler(error, vue);


router.afterEach((to) => {
  var path = to.path.substr(0, to.path.lastIndexOf('/'));
  store.commit("setCurrentUrl", path);
})
router.beforeEach((to, from, next) => {
  // var sdata = require('../public/config.json');
  //检查登录
  routeAction.loginAction(to.matched.some(r => r.meta.login))
    // .then(() => {
    //   //检查广播
    //   return routeAction.broadAction(to.matched.some(r => r.meta.broad), sdata)
    // })
    .then(() => {
      //检查设备类型
      return routeAction.dtypeAction(to.matched.some(r => r.meta.dtype));
    })
    .then(() => {
      //检查设备类型
      return routeAction.ltypeAction(to.matched.some(r => r.meta.ltype));
    })
    .then(() => {
      //检查设备类型
      return routeAction.lampsAction(to.matched.some(r => r.meta.lamps));
    })
    .then(() => {
      //检查顺州参数
      return routeAction.szAction(to.matched.some(r => r.meta.sz));
    }).then(() => {
      //检查项目参数
      return routeAction.projectAction(to.matched.some(r => r.meta.project));
    }).then(() => {
      //检查项目参数
      return routeAction.alarmAction(to.matched.some(r => r.meta.alarm));
    })
    .then(() => {

      next();
    })
    .catch(
      (path) => {
        next(path);
      }
    )
});
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
