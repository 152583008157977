<template>
    <div  style="width:100%">
         <div class="nhome_main_header" style="width:100%">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        :style="'background-color: transparent !important; padding-left:0px'"
      >
        <b-navbar-brand >
          <router-link to="/admin/home">
          <b-img :src="$store.state.project.Logo" style="height:35px"></b-img></router-link>
          <span v-show="tran" style="padding-left:15px;font-size:20px" class="smhide">{{$store.state.project.ProjectName}}</span>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav></b-navbar-nav>
          <b-navbar-nav class="ml-auto">
             <b-nav-item-dropdown>
              <template slot="button-content">
                <img :src="baseUrl+'/nicon/lan.png'" class="title-img"/>
                <span  style="color:white">{{lang=='zh'?'中文':'English'}}</span>
              </template>
              <b-dropdown-item @click="lanChange('zh')">中文</b-dropdown-item>
              <b-dropdown-item @click="lanChange('en')">English</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item>
              <router-link to="/admin/setting"  style="color:white">
                <img :src="baseUrl+'/nicon/set.png'" class="title-img"/>
                {{$t('设置')}}
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link to="/admin/alarm" style="color:white">
                <img :src="baseUrl+'/nicon/msg.png'" class="title-img"/>
                {{$t('消息')}}
              </router-link>
            </b-nav-item>
            <b-nav-item-dropdown right>
              <template slot="button-content">
                <img :src="baseUrl+'/nicon/admin.png'" class="title-img"/>
                <em  style="color:white">{{userName}}</em>
              </template>
              <b-dropdown-item to="/admin/setting/password">{{$t('修改密码')}}</b-dropdown-item>
              <b-dropdown-item @click="logout">{{$t('安全退出')}}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    </div>
</template>
<style scoped>
.navbar-expand-lg{
  z-index: 100;
}
</style>
<script>
import config from "@/assets/utils/config";
export default {
    props:{
        tran:true
    },
     data() {
    return {
      baseUrl:config.BaseUrl,
      lang:"zh",
      menu:[],
      userName:this.$cookies.get("username")
    };
  },
  mounted:function(){
     var lan = this.$cookies.get("lan");
     this.lang=lan;
    this.$store.commit("setLan", lan);
  },
  methods:{
    
      logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if(this.$route.path.toLowerCase()=="/admin/nhome"){
        this.$router.push({ path: "/info/home?reload=true" });
      }else{
      this.$router.push({ path: "/info/login?reload=true" });}
    },
    lanChange(lan) {
      this.lang=lan;
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    }
  }
}
</script>