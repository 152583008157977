<template>
  <div class="oview_content clearfix" v-loading="loading">
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate2"></toprate>
      </box>
    </div>
    <div class="oview_content_3">
      <box>
        <toprate ref="toprate3"></toprate>
      </box>
    </div>
    <div class="oview_content_1">
      <box> 
          <pie :radius="true" ref="pie" :centerlable="$t('总数量')"></pie>
      </box>
    </div>
    <div class="oview_content_1">
      <box>
        <bar ref="bar"></bar>
      </box>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import rate from "@/components/chart/rate.vue";
import charslider from "@/components/chart/charslider.vue";
import toprate from "@/components/chart/toprate.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
export default {
  components: { box, rate, toprate, charslider, pie, bar, linebar },
  data: function () {
    return {
      current: {
        EnergyPlan: {},
        EnergyPandect: {
          CurrentMonthEnergy: {},
          LastMonthEnergy: {},
          LastYearSameMonthEnergy: {},
          LinkRelativeRatio: 0,
          YearOnYearRatio: 0,
        },
      },
      loading: false,
      chars: [],
      color1: "#FFB744",
      color2: "#3197FA",
      color3: "#FF4848",
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f",
          },
          {
            offset: 1,
            color: "#FFB744ff",
          },
        ],
      },
      lineColor2: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#3197FA0f",
          },
          {
            offset: 1,
            color: "#3197FAff",
          },
        ],
      },
      lineColor3: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FF48480f",
          },
          {
            offset: 1,
            color: "#FF4848ff",
          },
        ],
      },
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/overview/GetCameraStatistics", "", true)
        .then((data) => {
          this.loading = false;
          this.$refs.toprate.setAndInit(
            data.OnlineCount,
            data.TotalCount - data.OnlineCount,
            this.$t("在线数量"),
            0,
            this.$t("在线率")
          );
          this.$refs.toprate2.setAndInit(
            data.OfflineCount,
            data.TotalCount - data.OfflineCount,
            this.$t("离线数量"),
            1,
            this.$t("离线率")
          );
          this.$refs.toprate3.setAndInit(
            0,
            data.TotalCount - 0,
            this.$t("告警数量"),
            2,
            this.$t("告警率")
          );
          var opt = {
            title: {
              show: true,
              text: this.$t("设备分析"),
              left: 10,
            },
            legend:{
                 orient: "vertical",
                left: 10,
                data: [this.$t("在线"), this.$t("离线")],
                bottom: 5,
                show: true,
            },
            data:[
                {
              value: data.OnlineCount,
              name: this.$t("在线"),
            },
            {
              value: data.OfflineCount,
              name: this.$t("离线"),
            }
            ],
            color:
                [ this.color2,this.color1]
          };
          this.$refs.pie.setAndInit(opt.data,opt.legend,opt.color,null,opt.title);
          opt.data=[{
              name: this.$t("设备分析"),
              data:[
                  data.OnlineCount, data.OfflineCount, data.TotalCount
              ]
          }
          ];
          opt.xdata=[
            this.$t("在线数量"),
            this.$t("离线数量"),
            this.$t("总数量"),
          ];
          opt.legend = {
            data: [
              this.$t("在线数量"),
              this.$t("离线数量"),
              this.$t("总数量"),
            ]
          }
          opt.color=[this.color1,this.color2];
          this.$refs.bar.setAndInit(opt.data,opt.legend,opt.color,null,opt.xdata,opt.title,true,"30%")
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>
