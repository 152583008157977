import Vue from 'vue'
import Router from 'vue-router'
import App from './App'
import login from './view/login'
import admin from './view/nadmin'
import xixun from './view/XiXun'
import error from './view/error'
import ledView from './view/ledview'

import aview from './view/aview'
import adview from './view/overview'
// import home from './view/home'
import newhome from './view/newHome'

import oview from './view/admin/noverview'

import video from './view/admin/video'
import cams from './view/admin/video/Cams'
import camsview from './view/admin/video/CamsView'
import natcam from './view/admin/video/NatCam'
import netcam from './view/admin/video/NetCam'
import playback from './view/admin/video/PlayBack'
import camAlarm from './view/admin/video/Alarm' 


import broad from './view/admin/broad'
import BroadClient from './view/admin/broad/BroadClient'
import Meida from './view/admin/broad/Meida'
import OverView from './view/admin/broad/OverView'
import Part from './view/admin/broad/Part'
import Timmer from './view/admin/broad/Timmer'


import stand from './view/admin/stand'
import Stands from './view/admin/stand/Stands'
import StandAlarms from './view/admin/stand/Alarms'
import StandOverview from './view/admin/stand/Overview'
import StandDetail from './view/admin/stand/StandDetail'
import Basic from './view/admin/stand/Basicinfo'
import Standinfo from './view/admin/stand/Standinfo'
import Leddetail from './view/admin/stand/Leddetail'
import Broaddetail from './view/admin/stand/Broaddetail'
import Sensordetail from './view/admin/stand/Sensordetail'
import Commondevice from './view/admin/stand/Commondevice'
import Checkdetail from './view/admin/stand/Checkdetail'
import Errordetail from './view/admin/stand/Errordetail'

import light from './view/admin/light'
import Geteway from './view/admin/light/Geteway'
import LControl from './view/admin/light/LControl'
import Lights from './view/admin/light/Lights'
import Loop from './view/admin/light/Loop'
import Scene from './view/admin/light/Scene'
import LightView from './view/admin/light/View'
import lightalarm from './view/admin/led/ledalarm'

import sensor from './view/admin/sensor'
import Sensors from './view/admin/sensor/Sensors'
import SensorView from './view/admin/sensor/SensorView'
import SensorAlarm from './view/admin/sensor/alarm'


import led from './view/admin/led'
import Leds from './view/admin/led/leds'
import LedStage from './view/admin/led/stage'
import LedResource from './view/admin/led/resources'
import Ledtimmer from './view/admin/led/ledtimmer.vue'
import Ledconfig from './view/admin/led/ledconfig'
import ledview from './view/admin/led/view'
import ledXixun from './view/admin/led/xixun'

import setting from './view/admin/setting'
import alarm from './view/admin/alarm'
import broadset from './view/admin/setting/broadset'
import alarmer from './view/admin/setting/alarmer'
import alarms from './view/admin/setting/alarms'
import password from './view/admin/setting/password'
import partment from './view/admin/setting/partment'

import wifi from './view/admin/wifi'
import ap from './view/admin/wifi/ap'
import ac from './view/admin/wifi/ac'
import acview from './view/admin/wifi/view'
import acDetail from './view/admin/wifi/acDetail'

import water from './view/admin/water'
import waterView from './view/admin/water/view'
import waterSensor from './view/admin/water/sensors'
import waterDetail from './view/admin/water/detail'
import waterAlarm from './view/admin/water/alarm'

import conver from './view/admin/conver'
import converView from './view/admin/conver/view'
import converSensor from './view/admin/conver/sensors'
import converDetail from './view/admin/conver/detail'
import converAlarm from './view/admin/conver/alarm'

import car from './view/admin/car'
import carView from './view/admin/car/view'
import carSensor from './view/admin/car/sensors'
import carDetail from './view/admin/car/detail'
import carAlarm from './view/admin/car/alarm'


import hydrant from './view/admin/hydrant'
import hydrantView from './view/admin/hydrant/view'
import hydrantSensor from './view/admin/hydrant/sensors'
import hydrantDetail from './view/admin/hydrant/hydetail'
import hydrantAlarm from './view/admin/hydrant/alarm'


import info from './view/info'
import infoLogin from './view/info/nlogin'
import infoSoft from './view/info/soft'
import infoStand from './view/info/stand'
import infoContact from './view/info/contact'
import infoProduct from './view/info/product'
import infoDownload from './view/info/download'
import infoPrice from './view/info/price'
import infoProductDetail from './view/info/productdetail'


import Afr from './view/admin/afr'
import AfrView from './view/admin/afr/view'
import AfrFaces from './view/admin/afr/faces'
import AfrMoreFaces from './view/admin/afr/moreFaces'
import AfrMonitor from './view/admin/afr/monitor'
import AfrDetail from './view/admin/afr/detail'
import AfrDevices from './view/admin/afr/devices'
import AfrDevView from './view/admin/afr/devView'
import AfrDevView2 from './view/admin/afr/devView2'
import AfrMsgs from './view/admin/afr/msgs'
import AfrRoadmap from './view/admin/afr/roadmap'
import AfrAlarm from './view/admin/afr/alarm'


import alarmD from './view/admin/alarms'
import alarmDS from './view/admin/alarm/alarms'
import alarmDa from './view/admin/alarm/alarm'
// import alarmsAction from './view/admin/alarm/alarmAction'

import xixunplay from './view/admin/xixun'
import xixunscreen from './view/admin/xixun/screen'
import xixunresource from './view/admin/xixun/resource'
import xixunprogram from './view/admin/xixun/program'
import progDetail from './view/admin/xixun/progDetail'
import progTask from './view/admin/xixun/progTask'
import progLog from './view/admin/xixun/progLog'

import nova from "./view/admin/nova"
import novaDevice from './view/admin/nova/device';
import novaProg from './view/admin/nova/prog';
import novaProgDetail from './view/admin/nova/progDetail';
import novaTask from './view/admin/nova/novaTask';
import novaLog from './view/admin/nova/novaLog';
import novacTimming from './view/admin/nova/cTimming';

import novaBroad from "./view/admin/novaBroad"
import novaBroadRes from "./view/admin/novabroad/videoRes"
import broadList from "./view/admin/novabroad/videoList"
import broadTimmer from "./view/admin/novabroad/videoTimmer"


import voice from "./view/admin/voice"
import voiceDev from "./view/admin/voice/dev"
import voiceAlarm from "./view/admin/voice/alarm"

import novaWeb from "./view/admin/novaWeb"
import novaWebLeds from "./view/admin/novaWeb/leds"
Vue.use(Router)
const routes = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: "home",
      component: App,
      redirect: "info/home",
      children: [
        {
          path: "/xixun",
          name: ".xixun",
          component: xixun,
        },
        {
          path: "/adview",
          name: ".adview",
          component: adview,
        },
        {
          path: "/aview",
          name: ".aview",
          component: aview,
        }, {
          path: "/home",
          name: ".home",
          component: newhome,
          meta: { login: true, project: true },
        },
        {
          path: "/admin/oview",
          name: "admin.oview",
          meta: { login: true, project: true, alarm: true },
          component: oview
        },
        {
          path: "/login",
          name: "login",
          component: login
        },
        {
          path: "/error",
          name: "error",
          component: error
        },
        {
          path: "/ledView",
          name: "ledView",
          component: ledView
        },
        {
          path: "/info",
          name: "info",
          component: info,
          redirect: "/info/home",
          children: [
            {
              path: "/info/login",
              name: "info.login",
              redirect: "/info/home",
              component: infoLogin,
            }, {
              path: "/info/home",
              name: "info.home",
              component: infoLogin,
            },
            {
              path: "/info/soft",
              name: "info.soft",
              component: infoSoft,
            },
            {
              path: "/info/stand",
              name: "info.stand",
              component: infoStand,
            },
            {
              path: "/info/product",
              name: "info.product",
              component: infoProduct,
            },
            {
              path: "/info/contact",
              name: "info.contact",
              component: infoContact,
            },
            {
              path: "/info/download",
              name: "info.download",
              component: infoDownload,
            },
            {
              path: "/info/price",
              name: "info.price",
              component: infoPrice,
            },
            {
              path: "/info/productdetail",
              name: "info.productdetail",
              component: infoProductDetail,
            }
          ]
        },
        {
          path: "/admin",
          name: "admin",
          component: admin,
          meta: { login: true, project: true, alarm: true },
          redirect: "/admin/home",
          children: [
            {
              path: "/admin/nhome",
              name: "admin.nhome",
              component: newhome,
              redirect:"/home"
            },
            {
              path: "/admin/home",
              name: "admin.home",
              redirect:"/home"
            }
            ,
            {
              path:"/admin/novaBroad",
              name:"admin.novaBroad",
              component:novaBroad,
              redirect:"/admin/NovaBroad/Res",
              children:[
                {
                  path: "/Admin/NovaBroad/Res",
                  name: "admin.NovaBroad.Res",
                  component: novaBroadRes,
                },{
                  path: "/Admin/NovaBroad/List",
                  name: "admin.NovaBroad.List",
                  component: broadList,
                },{
                  path: "/Admin/NovaBroad/Timmer",
                  name: "admin.NovaBroad.Timmer",
                  component: broadTimmer,
                }
              ]
            },
            {
              path:"/Admin/Voice",
              name:"admin.Voice",
              component:voice,
              redirect:"/Admin/Voice/Dev",
              children:[
                {
                  path: "/Admin/Voice/Dev",
                  name: "admin.Voice.Dev",
                  component: voiceDev,
                },
                {
                  path: "/Admin/Voice/Alarm",
                  name: "admin.Voice.Alarm",
                  component: voiceAlarm,
                }
              ]
            },
            {
              path:"/Admin/NwLed",
              name:"admin.NwLed",
              component:novaWeb,
              redirect:"/Admin/NwLed/Screens",
              children:[
                {
                  path: "/Admin/NwLed/Screens",
                  name: "admin.NwLed.Screens",
                  component: novaWebLeds,
                },
                {
                  path: "/Admin/NwLed/Resource",
                  name: "admin.NwLed.Resource",
                  component: LedResource,
                },{
                  path: "/Admin/NwLed/Stage",
                  name: "admin.NwLed.Stage",
                  component: LedStage
                },{
                  path: "/Admin/NwLed/Timmer",
                  name: "admin.NwLed.Timmer",
                  component: novacTimming,
                }
              ]
            },
            {
              path:"/admin/nova",
              name:"admin.nova",
              component:nova,
              redirect:"/admin/nova/device",
              children:[
                {
                  path: "/admin/nova/device",
                  name: "admin.nova.device",
                  component: novaDevice,
                  meta: { dtype: true ,lamps:true},
                },
                {
                  path: "/admin/nova/res",
                  name: "admin.nova.res",
                  component: LedResource
                },
                {
                  path: "/admin/nova/prog",
                  name: "admin.nova.prog",
                  component: novaProg
                },
                {
                  path: "/admin/nova/alarmProg",
                  name: "admin.nova.alarmProg",
                  component: LedStage
                },
                {
                  path: "/admin/nova/progDetail",
                  name: "admin.nova.progDetail",
                  component: novaProgDetail
                },
                {
                  path: "/admin/nova/task",
                  name: "admin.nova.task",
                  component: novaTask
                },
                {
                  path: "/admin/nova/log",
                  name: "admin.nova.log",
                  component: novaLog
                },
                {
                  path: "/admin/nova/ctimming",
                  name: "admin.nova.ctimming",
                  component: novacTimming
                }
              ]
            },
            {
              path: "/admin/water",
              name: "admin.water",
              component: water,
              redirect: "/admin/water/view",
              children: [
                {
                  path: "/admin/water/view",
                  name: "admin.water.view",
                  component: waterView,
                },
                {
                  path: "/admin/water/sensors",
                  name: "admin.water.sensors",
                  component: waterSensor,
                  meta: { dtype: true },
                },
                {
                  path: "/admin/water/alarm",
                  name: "admin.water.alarm",
                  component: waterAlarm,
                  meta: { dtype: true, broad: true },
                },
                {
                  path: "/admin/water/detail",
                  name: "admin.water.detail",
                  component: waterDetail,
                }
              ]
            },
            {
              path: "/admin/alarms",
              name: "admin.alarms",
              component: alarmD,
              redirect: "/admin/alarms/alarms",
              children: [
                {
                  path: "/admin/alarms/alarms",
                  name: "admin.alarms.alarms",
                  component: alarmDS,
                  meta: { lamps: true }
                },
                {
                  path: "/admin/alarms/alarmAction",
                  name: "admin.alarms.alarmAction",
                  component: netcam,
                },
                {
                  path: "/admin/alarms/alarm",
                  name: "admin.alarms.alarm",
                  component: alarmDa,
                }
              ]
            },
            {
              path: "/admin/conver",
              name: "admin.conver",
              component: conver,
              redirect: "/admin/conver/view",
              children: [
                {
                  path: "/admin/conver/view",
                  name: "admin.conver.view",
                  component: converView,
                },
                {
                  path: "/admin/conver/sensors",
                  name: "admin.conver.sensors",
                  meta: { dtype: true },
                  component: converSensor,
                },
                {
                  path: "/admin/conver/alarm",
                  name: "admin.conver.alarm",
                  component: converAlarm,
                  meta: { dtype: true, broad: true },
                },
                {
                  path: "/admin/conver/detail",
                  name: "admin.conver.detail",
                  component: converDetail,
                }
              ]
            },
            {
              path: "/admin/car",
              name: "admin.car",
              component: car,
              redirect: "/admin/car/view",
              children: [
                {
                  path: "/admin/car/view",
                  name: "admin.car.view",
                  component: carView,
                },
                {
                  path: "/admin/car/sensors",
                  name: "admin.car.sensors",
                  meta: { dtype: true },
                  component: carSensor,
                },
                {
                  path: "/admin/car/detail",
                  name: "admin.car.detail",
                  component: carDetail,
                },
                {
                  path: "/admin/car/alarm",
                  name: "admin.car.alarm",
                  component: carAlarm,
                }
              ]
            },
            {
              path: "/admin/hydrant",
              name: "admin.hydrant",
              component: hydrant,
              redirect: "/admin/hydrant/view",
              children: [
                {
                  path: "/admin/hydrant/view",
                  name: "admin.hydrant.view",
                  component: hydrantView,
                },
                {
                  path: "/admin/hydrant/sensor",
                  name: "admin.hydrant.sensor",
                  meta: { dtype: true },
                  component: hydrantSensor,
                },
                {
                  path: "/admin/hydrant/detail",
                  name: "admin.hydrant.detail",
                  component: hydrantDetail,
                },
                {
                  path: "/admin/hydrant/alarm",
                  name: "admin.hydrant.alarm",
                  component: hydrantAlarm,
                }
              ]
            },
            {
              path: "/admin/wifi",
              name: "admin.wifi",
              component: wifi,
              redirect: "/admin/wifi/view",
              children: [{
                path: "/admin/wifi/ap",
                name: "admin.wifi/ap",
                meta: { lamps: true, dtype: true },
                component: ap,
              }, {
                path: "/admin/wifi/ac",
                name: "admin.wifi/ac",
                meta: { lamps: true, dtype: true },
                component: ac,
              }, {
                path: "/admin/wifi/view",
                name: "admin.wifi/view",
                component: acview,
              }, {
                path: "/admin/wifi/acDetail",
                name: "admin.wifi/acDetail",
                component: acDetail,
              }
              ]
            },
            {
              path: "/admin/cam",
              name: "admin.video",
              component: video,
              redirect: "/admin/cam/camsview",
              children: [
                {
                  path: "/admin/cam/cams",
                  name: "admin.video.cams",
                  meta: { dtype: true, lamps: true },
                  component: cams
                },
                {
                  path: "/admin/cam/camsview",
                  name: "admin.video.camsview",
                  component: camsview
                },
                {
                  path: "/admin/cam/natcam",
                  name: "admin.video.natcam",
                  component: natcam
                },
                {
                  path: "/admin/cam/netcam",
                  name: "admin.video.netcam",
                  component: netcam
                },
                {
                  path: "/admin/cam/playback",
                  name: "admin.video.playback",
                  component: playback
                },
                {
                  path: "/admin/cam/alarm",
                  name: "admin.video.alarm",
                  component: camAlarm
                }
              ]
            },
            {
              path: "/admin/broad",
              name: "admin.broad",
              component: broad,
              redirect: "/admin/broad/overview",
              children: [
                {
                  path: "/admin/broad/broadclient",
                  name: "admin.video.broadclient",
                  component: BroadClient,
                  meta: { broad: true, dtype: true, lamps: true },
                },
                {
                  path: "/admin/broad/media",
                  name: "admin.video.Meida",
                  component: Meida,
                  meta: { broad: true },
                },
                {
                  path: "/admin/broad/overview",
                  name: "admin.video.OverView",
                  component: OverView
                },
                {
                  path: "/admin/broad/part",
                  name: "admin.video.part",
                  component: Part
                },
                {
                  path: "/admin/broad/timmer",
                  name: "admin.video.Timmer",
                  meta: { broad: true },
                  component: Timmer
                }
              ]
            },
            {
              path: "/admin/stand",
              name: "admin.stand",
              component: stand,
              redirect: "/admin/stand/overview",
              children: [
                {
                  path: "/admin/stand/LampStands",
                  name: "admin.stand.stands",
                  meta: { ltype: true },
                  component: Stands
                },
                {
                  path: "/admin/stand/standDetail",
                  name: "admin.stand.standDetail",
                  component: StandDetail
                },
                {
                  path: "/admin/stand/alarms",
                  name: "admin.stand.alarms",
                  component: StandAlarms
                },
                {
                  path: "/admin/stand/overview",
                  name: "admin.stand.overview",
                  component: StandOverview
                },
                {
                  path: "/admin/stand/Basic",
                  name: "admin.stand.Basic",
                  component: Basic
                },
                {
                  path: "/admin/stand/Standinfo",
                  name: "admin.stand.Standinfo",
                  component: Standinfo,
                  meta: { broad: true },
                },
                {
                  path: "/admin/stand/Leddetail",
                  name: "admin.stand.Leddetail",
                  component: Leddetail,
                },
                {
                  path: "/admin/stand/Broaddetail",
                  name: "admin.stand.Broaddetail",
                  component: Broaddetail,
                  meta: { broad: true },
                },
                {
                  path: "/admin/stand/Sensordetail",
                  name: "admin.stand.Sensordetail",
                  component: Sensordetail,
                },
                {
                  path: "/admin/stand/Commondevice",
                  name: "admin.stand.Commondevice",
                  component: Commondevice,
                },
                {
                  path: "/admin/stand/Checkdetail",
                  name: "admin.stand.Checkdetail",
                  component: Checkdetail,
                },
                {
                  path: "/admin/stand/Errordetail",
                  name: "admin.stand.Errordetail",
                  component: Errordetail,
                }
              ]
            },
            {
              path: "/admin/setting",
              name: "admin.setting",
              component: setting,
              redirect: "/admin/setting/password",
              children: [
                {
                  path: "/admin/setting/broadset",
                  name: "admin.setting.broadset",
                  component: broadset
                },
                {
                  path: "/admin/setting/password",
                  name: "admin.setting.password",
                  component: password
                },
              ]
            },
            {
              path: "/admin/alarm",
              name: "admin.alarm",
              component: alarm,
              redirect: "/admin/setting/alarms",
              children: [
                {
                  path: "/admin/setting/alarmer",
                  name: "admin.setting.alarmer",
                  component: alarmer
                },
                {
                  path: "/admin/setting/alarms",
                  name: "admin.setting.alarms",
                  component: alarms
                },
                {
                  path: "/admin/setting/partment",
                  name: "admin.setting.partment",
                  component: partment
                }
              ]
            },
            {
              path: "/admin/light",
              name: "admin.light",
              component: light,
              redirect: "/admin/light/View",
              children: [
                {
                  path: "/admin/light/Geteway",
                  name: "admin.light.Geteway",
                  meta: { dtype: true, sz: true },
                  component: Geteway
                },
                {
                  path: "/admin/light/LControl",
                  name: "admin.light.LControl",
                  component: LControl
                },
                {
                  path: "/admin/light/Lights",
                  name: "admin.light.Lights",
                  meta: { dtype: true, sz: true, lamps: true },
                  component: Lights
                },
                {
                  path: "/admin/light/Loop",
                  name: "admin.light.Loop",
                  component: Loop
                },
                {
                  path: "/admin/light/Scene",
                  name: "admin.light.Scene",
                  component: Scene
                },
                {
                  path: "/admin/light/View",
                  name: "admin.light.View",
                  component: LightView
                },
                {
                  path: "/admin/light/alarm",
                  name: "admin.light.alarm",
                  component: lightalarm
                }
              ]
            },
            {
              path: "/admin/sensor",
              name: "admin.sensor",
              component: sensor,
              redirect: "/admin/sensor/SensorData",
              children: [
                {
                  path: "/admin/sensor/SensorData",
                  name: "admin.sensor.SensorData",
                  component: SensorView
                },
                {
                  path: "/admin/sensor/Sensors",
                  name: "admin.sensor.Sensors",
                  meta: { lamps: true, dtype: true },
                  component: Sensors
                },
                {
                  path: "/admin/sensor/SensorView",
                  name: "admin.sensor.SensorView",
                  meta: { lamps: true, dtype: true },
                  component: SensorView
                },
                {
                  path: "/admin/sensor/Alarm",
                  name: "admin.sensor.Alarm",
                  component: SensorAlarm
                }
              ]
            },
            {
              path: "/admin/Led",
              name: "admin.led",
              component: led,
              redirect: "/Admin/Led/View",
              children: [
                {
                  path: "/Admin/Led/Screens",
                  name: "admin.Led.Screens",
                  meta: { lamps: true, dtype: true },
                  component: Leds
                },
                {
                  path: "/Admin/Led/Resource",
                  name: "admin.Led.Resource",
                  component: LedResource
                },
                {
                  path: "/Admin/Led/Stage",
                  name: "admin.Led.Stage",
                  component: LedStage
                },
                {
                  path: "/Admin/Led/Timmer",
                  name: "admin.Led.Timmer",
                  component: Ledtimmer
                },
                {
                  path: "/Admin/Led/Ledconfig",
                  name: "admin.Led.Ledconfig",
                  component: Ledconfig
                },
                {
                  path: "/Admin/Led/View",
                  name: "admin.Led.View",
                  component: ledview
                },
                {
                  path: "/Admin/Led/xixun",
                  name: "admin.Led.xixun",
                  component: ledXixun
                }
              ]
            },
            {
              path: "/admin/Face",
              name: "admin.face",
              component: Afr,
              redirect: "/Admin/Face/View",
              children: [
                {
                  path: "/Admin/Face/FaceRoc",
                  name: "admin.Face.FaceRoc",
                  component: AfrFaces
                },
                {
                  path: "/Admin/Face/MoreFace",
                  name: "admin.Face.MoreFace",
                  component: AfrMoreFaces
                },
                {
                  path: "/Admin/Face/Faces",
                  name: "admin.Face.Faces",
                  component: AfrMonitor
                },
                {
                  path: "/Admin/Face/FaceDev",
                  name: "admin.Face.FaceDev",
                  component: AfrDevices
                },
                {
                  path: "/Admin/Face/DevView",
                  name: "admin.Face.DevView",
                  component: AfrDevView
                },
                {
                  path: "/Admin/Face/DevView2",
                  name: "admin.Face.DevView2",
                  component: AfrDevView2
                },
                {
                  path: "/Admin/Face/FaceMsg",
                  name: "admin.Face.FaceMsg",
                  component: AfrMsgs
                },
                {
                  path: "/Admin/Face/View",
                  name: "admin.Face.View",
                  component: AfrView
                },
                {
                  path: "/Admin/Face/Detail",
                  name: "admin.Face.detail",
                  component: AfrDetail,
                },
                {
                  path: "/Admin/Face/Roadmap",
                  name: "admin.Face.roadmap",
                  component: AfrRoadmap,
                },
                {
                  path: "/Admin/Face/alarm",
                  name: "admin.Face.alarm",
                  component: AfrAlarm,
                }
              ]
            },
            {
              path: "/admin/xixun",
              name: "admin.xixun",
              component: xixunplay,
              redirect: "/Admin/XiXun/Screens",
              children: [
                {
                  path: "/Admin/XiXun/Screens",
                  name: "admin.XiXun.Screens",
                  meta: { lamps: true, dtype: true },
                  component: xixunscreen
                },
                {
                  path: "/Admin/XiXun/Resource",
                  name: "admin.XiXun.Resource",
                  component: xixunresource
                },
                {
                  path: "/Admin/XiXun/Program",
                  name: "admin.XiXun.Program",
                  component: xixunprogram
                },
                {
                  path: "/Admin/XiXun/progDetail",
                  name: "admin.XiXun.progDetail",
                  component: progDetail
                },
                {
                  path: "/Admin/XiXun/Task",
                  name: "admin.XiXun.progTask",
                  component: progTask
                },
                {
                  path: "/Admin/XiXun/progLog",
                  name: "admin.XiXun.progLog",
                  component: progLog
                },
                {
                  path: "/Admin/XiXun/Timmer",
                  name: "admin.XiXun.Timmer",
                  component: Ledtimmer
                },
                {
                  path: "/Admin/XiXun/AStage",
                  name: "admin.XiXun.LedStage",
                  component: LedStage
                }
              ]
            }
          ]
        }
      ]
    }]
},
);
export default routes;