<template>
  <div class="homeBox" @click="to">
    <div class="homeBox-content">
      <div :style="style">
        <div style="position:absolute;right:0;bottom:0" class="homebox_img">
          <img :src="this.box.pic" style="width:100%;" />
        </div>
        <div style="position:absolute;top:0;padding-bottom:15px;padding:10px;font-weight:bold;" class="homebox-title">{{this.box.title}}</div>
        <div class="homebox_top"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    box: Object,
  },
  data() {
    return {
      style: this.getBackground(),
    };
  },
  mounted() {
    this.style.background = `linear-gradient(${this.box.start},${this.box.end})`;
  },
  methods: {
    // to() {
    //   if(this.box.path.indexOf("http:")!=-1){
    //     window.location.href=this.box.path;
    //   }else{
    //     this.$router.push({ path: this.box.path });
    //   }
      
    // },
    
    to() {
      var path=this.box.path;
      if (path.indexOf("http") != -1) {
        if (path.indexOf("light") != -1) {
          this.$ksajax
            .ksPostPromise("/api/smart/SetKSLight", "", true)
            .then((data) => {
              window.open(`${path}?ddl=${data}`)
            })
            .catch((err) => {
              this.$throw(err);
            });
        } else 
        if (path.indexOf("tunnel") != -1) {
          this.$ksajax
            .ksPostPromise("/api/smart/SetKSTunnel", "", true)
            .then((data) => {
              window.open(`${path}?ddl=${data}`)
            })
            .catch((err) => {
              this.$throw(err);
            });
        } 
        else {
          window.location.href = path;
        }
      } else {
        this.$router.push({ path: path });
      }
    },
    getBackground() {
      return {
        position: "relative",
        height: "100%",
        background: "",
        "border-radius": "3%",
      };
    },
  },
};
</script>
<style scoped>
.homebox-title{
  font-size: 16px;
}
.homex1 {
  width: 100%;
}
.homex5x2 {
  width: 40%;
}
.homex5 {
  width: 20%
}
.homeBox {
  position: relative;
  color: white;
  float: left;
  cursor: pointer;
}
.homex1.homeBox:after {
  content: "";
  display: block;
  padding-bottom: 50%;
}
.homex5.homeBox:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.homex5x2.homeBox:after {
  content: "";
  display: block;
  padding-bottom: 50%;
}
.homeBox-content {
  padding: 0px 10px 10px 0px;
}
.homex1.homeBox .homeBox-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homex5.homeBox .homeBox-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homex5x2.homeBox .homeBox-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
 .homebox_img {
  margin: 20px;
  width: 40%;
}
.homex1 .homebox_img {
  margin: 20px;
  width: 20%;
}
.homebox_top {
  position: absolute;
  border-radius: 3%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.homebox_top:hover {
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px;
}
@media (max-width: 960px) {
  .homex1 {
    width: 50%;
  }
  .homex5 {
    width: 33.33%;
  }
  .homex5x2 {
    width: 33.33%;
  }
  .homex5x2.homeBox:after {
  padding-bottom: 100%;
}
  .homex1.homeBox:after {
  padding-bottom: 100%;
}

.homebox-title{
  font-size: 22px;
}.homex1 .homebox_img {
  width: 40%;
}
}
@media (max-width: 512px){
.homebox-title{
  font-size: 16px;
}
}
</style>