<template>
  <div
    style="width: 100%; height: 100%; justify-content: center"
    class="ksflexcol"
  >
    <img :src="pic" style="width: 45%; max-width: 90%" />
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  props: ["sid", "stringvalue"],
  data() {
    return {
      pic: this.stringvalue,
    };
  },
  watch: {
    stringvalue: function () {
      this.pic = this.stringvalue;
    },
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (data.DeviceSid == this.sid) {
        this.pic = data.Data.PicUrl;
      }
    },
  },
};
</script> 