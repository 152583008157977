<template>
        <div class="ks_number">
            <div class="amount_title">{{title}}</div>
            <div class="amount_value">
                <ul> <li v-for="(item, index) in amountArr" :key="index">{{item}}</li> </ul>
            </div>
        </div>
</template>
<style scoped>
    .ks_number {
        width: 100%;
        height: 100%;
        position: relative;
        /*border: 1px solid #3274b8;*/
        font-size: 20px;
        color: #fff;
    }

        .ks_number .amount_title, .ks_number .amount_value ul li {
            /*border-radius: 5px;*/
            background: -webkit-linear-gradient( top,rgba(24,51,111,0.1), rgba(50,116,184,0.6), rgba(24,51,111,0.2));
            display: inline-flex;
            align-items: center;
            padding: 0 20px;
            height: 100%;
            margin-left: 5px;
            font-weight: bold;
            /*color: #35d5e3;*/
            color: #d9fcff;
            border: 1px solid #3274b8;
        }

        .ks_number .amount_value {
            display: inline-block;
            vertical-align: top;
            height: 100%;
        }

            .ks_number .amount_value ul {
                height: 100%;
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 40px;
            }

                .ks_number .amount_value ul li {
                    color: #fff;
                }
</style>

<script>
    import box from "@/components/box.vue"
    export default {
        components: { box },
        data: function () {
            return {
                message: 'Hello Vue!',
                duration: 3, // 持续时间
                curAmount: 0,
                maxAmount: 0,
                txtAmount: "",
                amountArr: [],
            };
        },
        props: ['title', 'amount', 'length'],
        mounted: function () {
            this.animation();
        },
        methods: {
            // 金额变动动画
            animation() {
                var that = this;
                this.maxAmount = this.amount;
                let addNum = 1;
                let thread;
                numSlideAnimation();

                function numSlideAnimation() {
                    that.curAmount += addNum;
                    if (that.curAmount >= that.maxAmount) {
                        that.curAmount = that.maxAmount;
                        if (thread) cancelAnimationFrame(thread)
                    } else {
                        thread = requestAnimationFrame(numSlideAnimation);
                    }
                    that.decimalString();
                }
            },
            decimalString() {
                let numStr = this.curAmount.toString();
                let cover = "00000".substr(0, this.length - numStr.length);
                this.txtAmount = cover + numStr;
                this.amountArr = this.txtAmount.split("");
            },
        } // end methods
    }
</script>
