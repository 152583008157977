<template>
  <div class="oview_content clearfix" v-loading="loading">
    <div class="oview_sider_div oview_div">
      <box>
        <pie
          ref="piePlan"
          :radius="true"
          :centerlable="$t('总数量')"
          :size="'small'"
        ></pie>
      </box>
    </div>
    <div class="oview_center_div oview_div" v-loading="loadingCam">
      <box>
        <div style="height: 100%" class="ksflexcol">
          <div style="width: 100%; flex: 1">
            <netvideo
              ref="camininet"
              :showRight="false"
              :playdata="playdata"
              :unshowaction="true"
            ></netvideo>
          </div>
          <div
            style="width: 100%; height: 80px; padding: 10px 0 0 0"
            class="facePic"
            ref="picContain"
            id="picContain"
          >
            <b-img
              v-for="item in facePics"
              :key="item.sid"
              :src="item.path"
              style="height: 100%; padding-left: 5px"
            ></b-img>
          </div>
        </div>
      </box>
    </div>
    <div class="oview_sider_div oview_div">
      <box>
        <pie
          ref="piePlan1"
          :radius="true"
          :centerlable="$t('总数量')"
          :size="'small'"
        ></pie>
      </box>
    </div>
    <div class="oview_100 oview_div">
      <box> <linebar ref="line" :smooth="true"></linebar></box>
    </div>
  </div>
</template>
<style scoped>
.facePic {
  overflow: auto;
}
</style>
<script>
import box from "@/components/box1.vue";
import pie from "@/components/chart/pie.vue";
import linebar from "@/components/chart/line.vue";
import charslider from "@/components/chart/charslider.vue";
import netvideo from "@/components/netvideo";
export default {
  components: { box, pie, linebar, charslider, netvideo },
  data() {
    return {
      sid: this.$route.query.sid,
      facePics: [
        {
          sid: Math.random(),
          path: "/img/unknow.png",
        },
      ],
      playdata: {},
      current: {
        FaceRecognition: {},
        RecognitionTrend: {},
        Sex: {},
      },
      loadingCam: false,
      loading: false,
      chars: [],
      color1: "#FFB744",
      color2: "#3197FA",
      color3: "#FF4848",
      color4: "#31B748",
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f",
          },
          {
            offset: 1,
            color: "#FFB744ff",
          },
        ],
      },
      lineColor2: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#3197FA0f",
          },
          {
            offset: 1,
            color: "#3197FAff",
          },
        ],
      },
      lineColor3: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FF48480f",
          },
          {
            offset: 1,
            color: "#FF4848ff",
          },
        ],
      },
    };
  },
  mounted: function () {
    this.select();
  },
  watch: {
    "$store.state.faceHubChange": function () {
      var data = this.$store.state.faceHub;
      var type = data.Type;
      var sid = data.Sid;
      switch (type) {
        case 0: // 0表示人脸检测信息。
          // 过滤非本摄像头
          if (this.sid === data.DeviceSid) this.getFaceId(sid);
          break;
        case 1: // 1表示人脸告警信息。
          if (this.sid === data.DeviceSid) this.getFaceId(sid);
          break;
        default:
          this.$throw("-类型");
          break;
      }
    },
  },
  methods: {
    test() {
      window.setInterval(() => {
        this.facePics.push({
          sid: Math.random(),
          path: "/img/unknow.png",
        });
        var sh = this.$refs.picContain.scrollHeight;
        this.$refs.picContain.scrollTo(0, sh);
      }, 2000);
    },
    getFaceId(sid) {
      this.$ksajax
        .ksPostPromise("/api/face/GetFaceBySid", sid, true)
        .then((data) => {
          this.facePics.push({
            sid: data.Sid,
            path: data.FaceUrl,
          });
        })
        .catch((err) => {
          this.$throw(err);
        });
    },
    package(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
    play(device) {
      var sid = this.$route.query.sid;
      var url = "/api/device/GetPayNet";
      var req = this.package(sid, "hd");
      this.loadingCam = true;
      this.$ksajax
        .ksPostPromise(url, req, true)
        .then((data) => {
          this.loadingCam = false;
          this.playdata.data = data;
          this.playdata.play = true;
          this.playdata.device = device;
          this.$refs.camininet.start();
          //   this.test();
        })
        .catch((err) => {
          this.$throw(err);
          this.loadingCam = false;
        });
    },
    select() {
      this.loading = true;
      var sid = this.$route.query.sid;
      this.$ksajax
        .ksPostPromise("/api/face/GetStatisticsByDevSid", sid, true)
        .then((data) => {
          this.loading = false;
          this.play(data.DeviceInfo);
          this.current = data;
          var chart1 = {
            title: {
              show: true,
              text: this.$t("人脸识别比例"),
              left: 10,
            },
            legend: {
              orient: "vertical",
              left: 10,
              data: [
                this.$t("普通客人"),
                this.$t("重要客人"),
                this.$t("陌生人"),
                this.$t("嫌疑人"),
              ],
              bottom: 5,
              show: true,
            },
            data: [
              {
                value: this.current.FaceRecognition.RegularCount,
                name: this.$t("普通客人"),
              },
              {
                value: this.current.FaceRecognition.VipCount,
                name: this.$t("重要客人"),
              },
              {
                value: this.current.FaceRecognition.StrangerCount,
                name: this.$t("陌生人"),
              },
              {
                value: this.current.FaceRecognition.SuspectCount,
                name: this.$t("嫌疑人"),
              },
            ],
            color: [this.color1, this.color4, this.color2, this.color3],
          };
          this.$refs.piePlan.setAndInit(
            chart1.data,
            chart1.legend,
            chart1.color,
            null,
            chart1.title
          );
          chart1.title.text = "性别识别比例";
          chart1.legend.data = [
            this.$t("男性"),
            this.$t("女性"),
            this.$t("未知"),
          ];
          chart1.data = [
            {
              value: this.current.Sex.MaleCount,
              name: this.$t("男性"),
            },
            {
              value: this.current.Sex.FemaleCount,
              name: this.$t("女性"),
            },
            {
              value: this.current.Sex.UnknownCount,
              name: this.$t("未知"),
            },
          ];
          this.$refs.piePlan1.setAndInit(
            chart1.data,
            chart1.legend,
            chart1.color,
            null,
            chart1.title
          );
          chart1.title.text = "人脸识别趋势分析";
          chart1.legend = null;
          var ddata = [];
          chart1.xdata = [];
          this.current.RecognitionTrend.forEach((element) => {
            ddata.push(element.Count);
            chart1.xdata.push(`${element.Month}.${element.Day}`);
          });
          chart1.data = [
            {
              name: this.$t("数量"),
              data: ddata,
              color: this.lineColor1,
            },
          ];
          this.$refs.line.setAndInit(
            chart1.data,
            null,
            chart1.color,
            null,
            chart1.xdata,
            chart1.title
          );
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
  },
};
</script>