<template>
  <div style="width: 100%; height: 100%; overflow: hidden" class="ksflex">
    <div style="width: 50%; color: white" class="ksflexcol">
      <div style="font-size: 24px">{{ name }}</div>
      <div :style="style">{{ count }}</div>
    </div>
    <div style="width: 50%; height: 100%">
      <rate ref="rate"></rate>
    </div>
  </div>
</template>
<script>
import rate from "@/components/chart/rate.vue";
export default {
  components: { rate },
  data() {
    return {
      name: "亮灯数量",
      count: "1000",
      style: {
        "font-size": "24px",
        color: "white",
      },
    };
  },
  methods: {
    setAndInit(data1, data2, name, type, chartName,israte) {
      this.name = name;
      this.count = !israte?data1:parseInt(data1/(data1+data2)*100)+'%';
      this.style.color =
        type == 0
          ? "#3197FA"
          : type == 1
          ? "#FFB744"
          : type == 2
          ? "#FF4848"
          : "white";
      return this.$refs.rate.setAndInit(data1, data2, chartName, type);
      
    },
  },
};
</script>