<template>
        <div class="ks_datetime">
            <div class="date">
                <h1>{{weekStr}}</h1>
                <p>{{dateStr}}</p>
            </div>
            <div class="amount_value">
                <ul> 
                    <li>{{timeArr[0]}}</li> 
                    <li>{{timeArr[1]}}</li> 
                    <span :class="{'active':isblink}">:</span>
                    <li>{{timeArr[2]}}</li> 
                    <li>{{timeArr[3]}}</li> 
                </ul>
            </div>
        </div>
</template>
<style scoped>
    .ks_datetime {
        width: 100%;
        height: 100%;
        position: relative;
        /*border: 1px solid #3274b8;*/
        font-size: 20px;
        color: #fff;
    }
    .ks_datetime .date{
        display:inline-block;
        align-items:center;
    }

        .ks_datetime .amount_title, .ks_datetime .amount_value ul li {
            /*border-radius: 5px;*/
            background: -webkit-linear-gradient( top,rgba(24,51,111,0.1), rgba(50,116,184,0.8), rgba(24,51,111,0.2));
            display: inline-flex;
            align-items: center;
            padding: 0 20px;
            height: 100%;
            margin-left: 5px;
            font-weight: bold;
            /*color: #35d5e3;*/
            color: #d9fcff;
            border: 1px solid #3274b8;
        }

        .ks_datetime .amount_value {
            display: inline-block;
            vertical-align: top;
            height: 100%;
        }
        .ks_datetime .amount_value span.active{
            opacity:0.1;
        }

            .ks_datetime .amount_value ul {
                height: 100%;
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 40px;
            }

                .ks_datetime .amount_value ul li {
                    color: #fff;
                }
</style>

<script>
    export default {
        data: function () {
            return {
                message: 'Hello Vue!',
                duration: 3, // 持续时间
                curAmount: 0,
                maxAmount: 0,
                txtAmount: "",
                amountArr: [],
                date:'',
                time_thread:null,
                dateStr:'',
                weekStr:'',
                timeArr:[],
                isblink: false,
            };
        },
        props: ['title', 'amount', 'length'],
        mounted: function () {
            this.animation();
            //this.time_thread = requestAnimationFrame(this.getDatetime);
            this.timer = setInterval(this.getDatetime, 1000);
            this.getDatetime();
        },
        methods: {
            // 金额变动动画
            animation() {
                var that = this;
                this.maxAmount = this.amount;
                let addNum = 1;
                let thread;
                numSlideAnimation();

                function numSlideAnimation() {
                    that.curAmount += addNum;
                    if (that.curAmount >= that.maxAmount) {
                        that.curAmount = that.maxAmount;
                        if (thread) cancelAnimationFrame(thread)
                    } else {
                        thread = requestAnimationFrame(numSlideAnimation);
                    }
                    that.decimalString();
                }
            },
            decimalString() {
                let numStr = this.curAmount.toString();
                let cover = "00000".substr(0, this.length - numStr.length);
                this.txtAmount = cover + numStr;
                this.amountArr = this.txtAmount.split("");
            },
           
            getDatetime() {
                var d = new Date();
                var y = d.getFullYear();
                var m = d.getMonth() + 1;
                var ds = d.getDate();
                if (ds <= 9) { ds = '0' + ds; }
                var h = d.getHours();
                if (h <= 9) { h = '0' + h; }
                var f = d.getMinutes();
                if (f <= 9) { f = '0' + f; }
                var s = d.getSeconds();
                if (s <= 9) { s = '0' + s; }
                var days = d.getDay();
                switch (days) {
                    case 1: days = '星期一'; break;
                    case 2: days = '星期二'; break;
                    case 3: days = '星期三'; break;
                    case 4: days = '星期四'; break;
                    case 5: days = '星期五'; break;
                    case 6: days = '星期六'; break;
                    case 0: days = '星期日'; break;
                }
                this.date = y + '-' + m + '-' + ds + ' ' + h + ':' + f + ':' + s + ' ' + days;
                this.dateStr = y + '年' + m + '月' + ds + '日';
                this.weekStr = days;
                this.timeStr = h.toString()+f.toString();
                this.timeArr = this.timeStr.split("");
                this.isblink = (s % 2 == 1);
            },
        } // end methods
    }
</script>
