<template>
  <div class="nmain top_main">
    <div id="main_top">
      <div id="main_top_left">
        <router-link :to="home">
          <b-img :src="$store.state.project.Logo" style="height:35px"></b-img>
            <!-- <router-link v-else  to="/admin/home" style="font-weight:bold;font-size:24px;color:white">{{$t('智慧杆平台')}}</router-link> -->
        </router-link>
      </div>
      <div id="main_top_center">{{$t("智慧城市")}}</div>
      <div id="main_top_right">
        <i class="fa fa-user-circle-o"></i>
        <span class="content_text">{{userName}}</span>
      </div>
    </div>
    <div id="oview_content" v-loading="loading">
      <div class="oview_content_c"> 
        <div style="padding-bottom:5px;height:50%;">
          <box>
            <div style="height:100%;width:100%;" id="onlineChat"></div>
          </box>
        </div>
        <div style="padding-bottom:5px;height:50%;">
          <box>
            <div style="height:100%;width:100%;" id="leftBottomChat"></div>
          </box>
        </div>
      </div>
      <div class="oview_content_c" id="oview_content_center">
        <!-- <div style="padding-bottom:5px;height:15%;" class="lhhide">
          <box>
            <div class="row" style="text-align:center">
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.LampStands?current.LampStands[0].TotalCount:0}}</span>
                <span class="content_text">{{$t("智慧灯杆")}}</span>
              </div>
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.LampStands?current.LampStands[1].TotalCount:0}}</span>
                <span class="content_text">{{$t("普通灯杆")}}</span>
              </div>
            </div>
          </box>
        </div>-->
        <div style="padding-bottom:5px;height:70%;">
          <box>
            <!-- <div style="height:100%;width:100%;" id="myChart"></div> -->
            <div style="height:100%;height:100%" v-loading="loadingMap">
              <basic :data="datas"></basic>
            </div>
          </box>
        </div>
        <div style="padding-bottom:5px;height:30%;" class="lhieght40">
          <box>
            <div class="row" style="text-align:center">
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="001").TotalCount:0}}</span>
                <span class="content_text">{{$t("摄像头")}}</span>
              </div>
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="006").TotalCount:0}}</span>
                <span class="content_text">{{$t("广告屏")}}</span>
              </div>
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="005").TotalCount:0}}</span>
                <span class="content_text">{{$t("传感器")}}</span>
              </div>
            </div>
            <div class="row" style="text-align:center">
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="004").TotalCount:0}}</span>
                <span class="content_text">{{$t("灯具")}}</span>
              </div>
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="003").TotalCount:0}}</span>
                <span class="content_text">{{$t("网关")}}</span>
              </div>
              <div class="col">
                <span
                  style="color:white;font-size:2.5em;display:block;font-family:Number"
                >{{current.Devices?current.Devices.find(x=>x.CategorySid=="002").TotalCount:0}}</span>
                <span class="content_text">{{$t("广播")}}</span>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="oview_content_c" id="oview_content_left">
        <div style="padding-bottom:5px;height:25%;">
          <box>
            <div style="height:100%;width:100%;" id="rightTopChat"></div>
          </box>
        </div>
        <div style="padding-bottom:5px;height:25%;">
          <box>
            <div style="position:relative;width:100%;height:100%">
              <alarm ref="alarm" right="15%"></alarm>
              <div style="padding:20px 0 0 5px">
                <div style="color:white;font-size:2rem">{{$t("告警分析")}}</div>
                <div style="font-size:80%;" class="content_text">Alarm Analysis</div>
              </div>
              <div style="position:absolute;bottom:10px;left:10px">
                <div style="color:white;font-size:20px">{{alarmData.title}}</div>
                <div class="content_text">{{alarmData.content}}</div>
              </div>
            </div>
          </box>
        </div>
        <div style="padding-bottom:5px;height:50%;">
          <box>
            <div style="height:100%;width:100%;" id="rightBottomChat"></div>
          </box>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>
<style scoped>
.oc_center_bottom_c {
  height: 100%;
  width: 100%;
}
.oc_center_bottom_c .ksrow {
  height: 50%;
}
.oview_content_c {
  float: left;
  height: 100%;
  padding: 5px;
  width: 33.33%;
}
#oview_content {
  color: white;
  height: 100%;
  height: calc(100% - 105px);
}
@media (max-width: 768px) {
  #main_top_center {
    display: none;
  }  #main_top_right{
    flex: unset;
  }
  .oview_content_c {
    width: 100%;
  }
  #oview_content_center {
    height: 150%;
  }
  #oview_content_left {
    height: 180%;
  }
}
</style>
<script>
import alarm from "@/components/alarm";
import box from "@/components/box.vue";
import basic from "@/components/basic";
import config from "@/assets/utils/config"
export default {
  watch: {
    "$store.state.alarmHubChange": function() {
      try {
        var data = this.$store.state.alarmHub;
        if (data.box) {
          this.alarmData = data.box;
          this.alarmTrigger = true;
        }
      } catch (err) {
        //
      }
    },
    alarmTrigger: function() {
      if (this.alarmTrigger) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    }
  },
  components: { alarm, box, basic },
  data: function() {
    return {
      isNeture:config.ISNETURE,
             home:this.$cookies.get("ksCustom")=="true"?"/admin/nhome":"/admin/home",
      deviceCount: {
        Light: 0,
        Led: 0,
        Broad: 0,
        Cam: 0,
        Water: 2,
        Cover: 1,
        Sensor: 2,
        Ac: 0,
        Ap: 0,
        Gate: 0,
        Car: 2
      },
      alarmTrigger: false,
      alarmData: {
        title: "",
        content: ""
      },
      timmer1: null,
      timmer2: null,
      timmer3: null,
      timmer4: null,
      timmer5: null,
      onlineChat: null,
      leftBottomChat: null,
      rightTopChat: null,
      rightBottomChat: null,
      myChart: null,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" }
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" }
      ]),
      logopic: require("@/assets/img/cute/logo.png"),
      loading: true,
      current: {},
      userName: this.$cookies.get("username"),
      loadingMap: false,
      datas: []
    };
  },
  mounted: function() {
    this.onlineChat = window.echarts.init(
      document.getElementById("onlineChat")
    );
    this.leftBottomChat = window.echarts.init(
      document.getElementById("leftBottomChat")
    );
    this.rightTopChat = window.echarts.init(
      document.getElementById("rightTopChat")
    );
    this.rightBottomChat = window.echarts.init(
      document.getElementById("rightBottomChat")
    );
    // this.myChart = window.echarts.init(document.getElementById("myChart"));
    // var type = this.$route.query.type;
    // type = type || 0;
    // type = type * 1;
    // switch (type) {
    //   case 0:
    //     this.beginMyChatChina();
    //     break;
    //   case 1:
    //     this.beginMyChat();
    //     break;
    //   case 2:
    //     this.centerData();
    //     break;
    // }
    //this.beginMyChat();
    this.select(true);
  },
  methods: {
    getMapData() {
      if (this.$store.state.devices.length == 0) {
        this.loadingMap = true;
        this.$ksajax
          .ksPostPromise("/api/info/GetDevice", "", true)
          .then(data => {
            this.$store.state.devices = data;
            this.datas = data;
            this.loadingMap = false;
          })
          .catch(err => {
            this.$throw(err, this);
            this.loadingMap = false;
          });
      } else {
        this.datas = this.$store.state.devices;
      }
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff"
          },
          orient: "vertical",
          right: 50
        }
      };
      return obj;
    },
    beginOnlineChart(data) {
      var labels = [];
      var online = [];
      var offline = [];
      for (var i = 0; i < data.length; i++) {
        online.push(data[i].OnlineCount);
        offline.push(data[i].OfflineCount);
        labels.push(
         this.$t( data[i].CategoryName)
          );
      }
      var datas = [];
      datas.push({
        name: this.$t("在线"),
        type: "bar",
        stack: this.$t("总量"),
        label: {
          normal: {
            show: false,
            position: "insideRight",
            color: "white"
          }
        },
        itemStyle: {
          normal: {
            color: this.color1
          }
        },
        data: online
      });
      datas.push({
        name: this.$t("离线"),
        type: "bar",
        stack: this.$t("总量"),
        label: {
          normal: {
            show: false,
            position: "insideRight",
            color: "white"
          }
        },
        itemStyle: {
          normal: {
            color: this.color2
          }
        },
        data: offline
      });
      var x = { splitLine: { show: false }, type: "value" };
      var y = {
        type: "category",
        splitLine: { show: false },
        data: labels
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("设备在线统计"),
        padding: [20, 0, 0, 5],
        textStyle: {
          color: "#ffffff"
        }
      }),
        option.legend.data.push(this.$t("在线"));
      option.legend.data.push(this.$t("离线"));
      this.onlineChat.setOption(option);
      window.addEventListener("resize", () => {
        this.onlineChat.resize();
      });
    },
    beginLeftBottomChart(data) {
      var online = [];
      var labels = [];
      for (var i = 0; i < data.length; i++) {
        online.push(data[i].TotalCount);
        labels.push(this.$t(data[i].CategoryName));
      }
      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "line",
        stack: this.$t("总量"),
        label: {
          normal: {
            show: true,
            position: "top",
            color: "white"
          }
        },
        smooth: true,
        data: online,
        itemStyle: {
          color: "#1b85c9"
        },
        areaStyle: {
          normal: {
            color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#00749a" },
              { offset: 0.5, color: "#123e62" },
              { offset: 1, color: "#132f57" }
            ])
          }
        }
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2"
          }
        },
        type: "value"
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("设备数量统计"),
        padding: [20, 0, 0, 5],
        textStyle: {
          color: "#ffffff"
        }
      }),
        this.leftBottomChat.setOption(option);
      window.addEventListener("resize", () => {
        this.leftBottomChat.resize();
      });
    },
    beginRightTopChart(data) {
      var on = data.LightUpCount;
      var off = data.LightOffCount;
      var sub = on + off;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: [this.$t("亮灯"), this.$t("关灯")],
          textStyle: {
            color: "#ffffff"
          }
        },
        series: [
          {
            name: this.$t("亮灯率"),
            type: "pie",
            radius: ["60%", "90%"],
            center: ["70%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总灯数")}}`,
                  `{count|${sub}}`
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 20
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 14,
                    padding: [0, 0, 10, 0]
                  }
                }
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: [
              { value: on, name: this.$t("关灯") },
              { value: off, name: this.$t("亮灯") }
            ],
            color: [this.color1, this.color2]
          }
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false
        }
      };
      option.title = {
        show: true,
        text: this.$t("灯具亮灯率"),
        padding: [20, 0, 0, 5],
        textStyle: {
          color: "#ffffff"
        }
      };
      this.rightTopChat.setOption(option, true);
      window.addEventListener("resize", () => {
        this.rightTopChat.resize();
      });
    },
    beginRightBottom(data) {
      var online = [];
      var labels = [];
      for (var i = 0; i < data.length; i++) {
        online.push(data[i].Energy + Math.random() * 10);
        labels.push(data[i].Year + "." + data[i].Month);
      }
      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "bar",
        stack: this.$t("总量"),
        smooth: true,
        data: online,
        barCategoryGap: "50%",
        itemStyle: {
          color: this.color1
        }
      });
      var x = {
        type: "category",
        data: labels,
        splitLine: { show: false },
        z: 10
      };
      var y = {
        type: "value",
        splitLine: { show: false },
        z: 10
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("能耗统计"),
        padding: [20, 0, 0, 5],
        textStyle: {
          color: "#ffffff"
        }
      }),
        this.rightBottomChat.setOption(option, true);
      window.addEventListener("resize", () => {
        this.rightBottomChat.resize();
      });
    },
    beginMyChat() {
      var option = {
        globe: {
          baseTexture: require("../../assets/img/earth.jpg"),

          displacementScale: 0.1,

          shading: "lambert",

          light: {
            ambient: {
              intensity: 0.5
            },
            main: {
              intensity: 1.5
            }
          },

          layers: [
            {
              type: "blend",
              blendTo: "emission",
              texture: "../../assets/img/night.jpg"
            },
            {
              type: "overlay",
              texture: "../../assets/img/clouds.png",
              shading: "lambert",
              distance: 5
            }
          ]
        },
        series: []
      };
      this.myChart.setOption(option, true);
    },

    select(loading) {
      if (loading) {
        this.loading = true;
      } else {
        this.loading = false;
      }
      //查询数据
      this.$ksajax
        .ksPostPromise("/api/overview/GetDashboardData", "", true)
        .then(data => {
          this.current = data;
          //在线率
          this.beginOnlineChart(data.Onlines);
          this.beginLeftBottomChart(data.Devices);
          this.beginRightTopChart(data.LightUpRate);
          this.beginRightBottom(data.Energies);
          this.loading = false;
          this.getMapData();
        })
        .catch(err => {
          this.$throw(err);
          this.loading = false;
        });
    }
  }
};
</script>
