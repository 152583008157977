<template>
  <div style="width: 100%; height: 100%;" class="">
    <el-drawer v-if="isDrawer"
      :visible.sync="drawer"
      :direction="direction"
       :with-header="false"
      size="200px"
      style="height:100%"
    >
         <el-menu
      default-active="2"
       class="el-menu-vertical-demo"
      background-color="#1d1e23"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="isCollapse"
      :router="true"
      style="border: none; height: 100%;"
    >
    <template  v-for="item in menu">
      <el-menu-item  :key="item.Sid" :index="item.Path" >
         <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
      </el-menu-item>
    </template>
     
    </el-menu>
    </el-drawer>
    <el-menu
      v-else
      default-active="0200001"
       class="el-menu-vertical-demo"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="isCollapse"
      :router="true"
      style="border: none; height: 100%; background-color:transparent"
    >
    <box>
    <template  v-for="item in menu">
      <el-menu-item  :key="item.Sid" :index="item.Path">
         <img :src="item.Pic" style="width:25px;padding-right:10px" />
          <span>{{item.Name}}</span>
      </el-menu-item>
    </template></box>
     
    </el-menu>
  </div>
</template>
<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
    min-height: 400px;
  }
  .el-drawer{
    height: 100%;
    /* overflow: auto; */
  }
</style>
<script>
import box from "@/components/box1.vue";
export default {
  components:{box},
  data: function () {
    return {
      items: [],
      direction: "ltr",
      drawer: false,
      isDrawer: false,
      isCollapse:false,
      menu:[]
    };
  },
  watch:{
    "$store.state.menu":function(){
      this.init();
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
     this.init();
    });
    this.init();
  },
  methods: {
    // urlClick(Path){
    // },
    init(){
      if(window.document.body.clientWidth<=1024){
        this.isDrawer=true;
      }else{
        this.isDrawer=false;
      }
      var m = this.$store.getters.getSonMenu("0");
      m.forEach((x) => {
        x.active = false;
      });
      this.menu = m.filter(x=>x.Path.indexOf("http:")==-1);
    },
    action() {
      this.drawer = !this.drawer;
      if(!this.isDrawer){
      this.isCollapse=!this.isCollapse;
      }
    },
  },
};
</script>