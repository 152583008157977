<template>
  <div id="main_top">
    <div id="main_top_left">
      <router-link to="/admin/home">
        <b-img :src="$store.state.project.Logo" style="height: 35px"></b-img>
        <!-- <router-link v-else  to="/admin/home" style="font-weight:bold;font-size:24px;color:white">{{$t('智慧杆平台')}}</router-link> -->
      </router-link>
    </div>
    <div id="main_top_center" class="lghide" style="font-size:27px">{{ $store.state.project.ProjectName }}</div>
    <div id="main_top_right" class="lghide">
      <a style="padding-right:10px">
        <img :src="baseUrl+'/nicon/lan.png'" class="title-img" />
        <el-dropdown trigger="click" @command="lanChange">
          <span style="color: white">{{
            lang == "zh" ? "中文" : "English"
          }} <i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </a>
      <a style="padding-right:10px">
         <router-link to="/admin/setting"  style="color:white">
                <img  :src="baseUrl+'/nicon/set.png'"  class="title-img"/>
                {{$t('设置')}}
              </router-link>
      </a>
      <a style="padding-right:10px">
              <router-link to="/admin/alarm" style="color:white">
                <img :src="baseUrl+'/nicon/msg.png'" class="title-img"/>
                {{$t('消息')}}
              </router-link>
      </a>
      <a>
           <img :src="baseUrl+'/nicon/admin.png'" class="title-img"/>
        <el-dropdown trigger="click" @command="adminChange">
          <span style="color: white">{{
            userName
          }} <i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">{{$t('安全退出')}}</el-dropdown-item>
            <el-dropdown-item command="to" to="/admin/setting/password">{{$t('修改密码')}}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </a>
    </div>
  </div>
</template>
<script>
import config from "../assets/utils/config"
export default {
     data() {
    return {
      baseUrl:config.BaseUrl,
      lang:"zh",
      menu:[],
      userName:this.$cookies.get("username")
    };
  },
  mounted:function(){
     var lan = this.$cookies.get("lan");
     this.lang=lan;
    this.$store.commit("setLan", lan);
  },
  methods:{
      adminChange(command){
          if(command=="logout"){
              this.logout();
          }else{
              this.$router.push("/admin/setting/password");
          }

      },
      logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if(this.$route.path.toLowerCase()=="/admin/nhome"){
        this.$router.push({ path: "/info/home?reload=true" });
      }else{
      this.$router.push({ path: "/info/login?reload=true" });}
    },
    lanChange(lan) {
      this.lang=lan;
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    }
  }
};
</script>